import { useEffect } from 'react';

import { useMsal } from '@azure/msal-react';

import { DURATIONS } from '../../config';
import { useBooking } from '../../providers/bookingProvider';
import { roundDateToTimeSlot } from '../../utils';
import Icon from '../Icon/Icon';
import Input from '../Input/Input';
import InputRange from '../Input/InputRange';
import TextArea from '../Input/TextArea';
import BookButton from './BookButton';
import InviteAttendees from './InviteAttendees';

import s from './BookingForm.module.scss';

const BookingForm = ({ roomId, roomEmail, roomName, isRefetching }) => {
  const {
    attendees,
    timeslot,
    duration,
    subject,
    description,
    isOverlap,
    setDescription,
    setDuration,
    setSubject,
    clearBookingForm,
    setTimeslot,
  } = useBooking();
  const { accounts } = useMsal();

  const durationIndex = DURATIONS.findIndex(({ value }) => value === duration);

  // INFO: reset the internal booking state on onmount for UX reasons.
  useEffect(() => {
    return () => {
      setTimeslot(roundDateToTimeSlot(new Date(), true));
      clearBookingForm();
    };
  }, [clearBookingForm, setTimeslot]);

  return (
    <form className={s.form} form="booking-form">
      <div className={s.subjectAndDuration}>
        <Input
          value={subject}
          placeholder={'Subject'}
          onChange={(e) => {
            setSubject(e.target.value);
          }}
        />
        <div className={s.inputRangeContainer}>
          <InputRange
            value={DURATIONS[durationIndex].label.toUpperCase()}
            iconType="operators"
            onIncrement={(e) => {
              e.preventDefault();
              if (durationIndex < DURATIONS.length) {
                setDuration(DURATIONS[durationIndex + 1].value);
              }
            }}
            onDecrement={(e) => {
              e.preventDefault();
              if (durationIndex > 0) {
                setDuration(DURATIONS[durationIndex - 1].value);
              }
            }}
            isIncrementDisabled={durationIndex === DURATIONS.length - 1}
            isDecrementDisabled={durationIndex === 0}
          />
        </div>
      </div>

      <InviteAttendees />
      <TextArea
        value={description}
        placeholder={'Meeting description (empty)'}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      <BookButton
        size="large"
        roomEmail={roomEmail}
        roomName={roomName}
        roomId={roomId}
        attendees={attendees}
        timeslot={timeslot}
        duration={duration}
        description={description}
        subject={subject}
        userDisplayName={accounts[0].username}
        isRefetching={isRefetching}
        isDisabled={isOverlap}
      >
        <span>Book</span> <Icon name="people" color="black" size="sm" />
      </BookButton>
    </form>
  );
};

export default BookingForm;
