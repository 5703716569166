import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const PinDuo = ({ color, outlineColor, width, height }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        d="M20.621 8.45C19.571 3.83 15.5409 1.75 12.0009 1.75H11.9909C8.46095 1.75 4.42095 3.82 3.37095 8.44C2.20095 13.6 5.36095 17.97 8.22095 20.72C9.23467 21.7012 10.5901 22.2498 12.0009 22.25C13.3609 22.25 14.7209 21.74 15.7709 20.72C18.6309 17.97 21.791 13.61 20.621 8.45Z"
        fill={outlineColor}
      />
      <path
        d="M12.0021 13.46C12.4157 13.46 12.8253 13.3785 13.2075 13.2202C13.5897 13.0619 13.9369 12.8299 14.2294 12.5374C14.5219 12.2449 14.754 11.8976 14.9123 11.5154C15.0706 11.1333 15.1521 10.7236 15.1521 10.31C15.1521 9.89631 15.0706 9.4867 14.9123 9.10452C14.754 8.72235 14.5219 8.37509 14.2294 8.08259C13.9369 7.79008 13.5897 7.55805 13.2075 7.39975C12.8253 7.24145 12.4157 7.15997 12.0021 7.15997C11.1666 7.15997 10.3654 7.49185 9.77466 8.08259C9.18392 8.67333 8.85205 9.47454 8.85205 10.31C8.85205 11.1454 9.18392 11.9466 9.77466 12.5374C10.3654 13.1281 11.1666 13.46 12.0021 13.46V13.46Z"
        fill={color}
      />
    </Svg>
  );
};

export default PinDuo;
