/**
 *
 * @param date1
 * @param date2
 * @returns a Boolean representing if the two dates are on the same day.
 */
const isSameDay = (date1: Date, date2: Date) =>
  new Date(date1).setHours(0, 0, 0, 0) === new Date(date2).setHours(0, 0, 0, 0);

export default isSameDay;
