import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { secondsTo } from '../utils';
import useMsGraph from './useMsGraph';

const usePollForDeletion = ({ roomId, iCalUId }) => {
  const [refetchInterval, setRefetchInterval] = useState(1000);
  const [deleted, setDeleted] = useState(false);
  const [eventId, setEventId] = useState();
  const [isPolling, setIsPolling] = useState(false);
  const queryClient = useQueryClient();

  const { getRoomEvents } = useMsGraph();

  const { error } = useQuery(['getRoomEvents'], getRoomEvents(roomId), {
    refetchIntervalInBackground: true,
    refetchInterval,
    onSuccess: (data) => {
      if (data.value.find((item) => item.iCalUId === iCalUId)) {
        // keep polling if event still exists
        setRefetchInterval(1 * secondsTo.milliseconds);
      } else {
        stopPolling();
        queryClient.invalidateQueries('getMyEvents');
        queryClient.invalidateQueries('getRoomEvents');
        queryClient.invalidateQueries(['getSchedule', roomId]);
      }
    },
    onError: () => setRefetchInterval(false),
    enabled: Boolean(eventId),
  });

  const startPolling = (id) => {
    setIsPolling(true);
    setEventId(id);
  };
  const stopPolling = () => {
    setIsPolling(false);
    setEventId(null);
    setRefetchInterval(false);
    setDeleted(true);
  };

  return { startPolling, stopPolling, deleted, error, isPolling };
};

export default usePollForDeletion;
