import minutesTo from '../minutesTo/minutesTo';

/**
 * Format dates from Graph API to correct timezone.
 * @param dateString
 * @returns date formatted to CET
 */
const createCETDate = (dateString: string | Date) => {
  let dateTime: Date;
  if (typeof dateString === 'string') {
    dateTime = new Date(dateString);
  } else {
    dateTime = dateString;
  }

  return new Date(dateTime.getTime() - dateTime.getTimezoneOffset() * minutesTo.milliseconds);
};

export default createCETDate;
