import { DURATIONS, STORAGE_DURATION } from '../../config';
import { Duration } from '../../types/booking';

export const getDefaultDuration = () => {
  const localDefaultDuration = localStorage.getItem(STORAGE_DURATION);

  if (localDefaultDuration) {
    try {
      const parsedValue = JSON.parse(localDefaultDuration) as Duration;
      if (typeof parsedValue === 'number' || !parsedValue.label || !parsedValue.value) {
        throw new Error(`No ${STORAGE_DURATION} in localStorage`);
      }

      return parsedValue;
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message);
      } else {
        console.log('Unexpected error', err);
      }
    }
  }

  localStorage.setItem(STORAGE_DURATION, JSON.stringify(DURATIONS[1]));

  return DURATIONS[1];
};

export default getDefaultDuration;
