import { AccountInfo, AuthenticationResult, IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { loginRequest } from '../authConfig';
import { MS_GRAPH_BASE_URL } from '../config';

const getAccessToken = async (instance: IPublicClientApplication, account: AccountInfo) => {
  try {
    const { accessToken } = await instance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    return accessToken;
  } catch {
    try {
      await instance.acquireTokenRedirect(loginRequest);
    } catch (e) {
      console.error('An error occurred while fetching access Token');
    }
  }
};

const useMsGraphCall = () => {
  const { instance, accounts } = useMsal();

  const msGraphHeaders = (accessToken: AuthenticationResult['accessToken']) =>
    new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      Prefer: `outlook.timezone = "UTC"`,
    });

  return {
    apiPost:
      <Response, Body>(endpoint: string, body: Body): (() => Promise<Response>) =>
      async () => {
        const accessToken = await getAccessToken(instance, accounts[0]);

        if (!accessToken) {
          throw Error('Access token undefined');
        }

        try {
          const response = await fetch(`${MS_GRAPH_BASE_URL}${endpoint}`, {
            method: 'POST',
            headers: msGraphHeaders(accessToken),
            body: JSON.stringify(body),
          });

          if (!response.ok) throw new Error('Could not post');

          return response.json();
        } catch (error) {
          throw error;
        }
      },

    apiGet:
      <Response>(endpoint: string): (() => Promise<Response>) =>
      async () => {
        const accessToken = await getAccessToken(instance, accounts[0]);

        if (!accessToken) {
          throw Error('Access token undefined');
        }

        try {
          const response = await fetch(`${MS_GRAPH_BASE_URL}${endpoint}`, {
            headers: msGraphHeaders(accessToken),
          });

          if (!response.ok) throw new Error('Could not get');

          return response.json();
        } catch (error) {
          throw error;
        }
      },

    // Fallback hits if no results for first query to endpoint 1.
    apiGetWithFallback: (endpoint1: string, endpoint2: string) => async () => {
      const accessToken = await getAccessToken(instance, accounts[0]);

      if (!accessToken) {
        throw Error('Access token undefined');
      }

      return (
        await fetch(`${MS_GRAPH_BASE_URL}${endpoint1}`, {
          headers: msGraphHeaders(accessToken),
        })
      )
        .json()
        .then(async (res) => {
          if (!res.value.length) {
            const fallbackRes = await fetch(`${MS_GRAPH_BASE_URL}${endpoint2}`, {
              headers: msGraphHeaders(accessToken),
            });

            return fallbackRes.json();
          } else {
            return res;
          }
        });
    },

    apiDelete: (endpoint: string) => async () => {
      const accessToken = await getAccessToken(instance, accounts[0]);

      if (!accessToken) {
        throw Error('Access token undefined');
      }

      const response = await fetch(`${MS_GRAPH_BASE_URL}${endpoint}`, {
        method: 'DELETE',
        headers: msGraphHeaders(accessToken),
      });

      if (!response.ok) throw new Error('Could not delete');
    },
  };
};

export default useMsGraphCall;
