import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useDebounce } from '../../hooks/useDebounce';
import { useBooking } from '../../providers/bookingProvider';
import { useDate } from '../../providers/dateProvider';
import { CalendarMeeting } from '../../types/calendar-event';
import { minutesTo } from '../../utils';
import { getPercentOfDay } from '../../utils/getPercentageOfDay/getPercentageOfDay';
import minutesInOneDay from '../../utils/minutesInOneDay/minutesInOneDay';
import roundDateToTimeSlot from '../../utils/roundDateToTimeSlot/roundDateToTimeSlot';
import Clock from './Clock';
import InnerTimeline from './InnerTimeline';
import Meeting from './Meeting';
import OuterTimeline from './OuterTimeline';

import './MeetingSchedule.scss';

interface IMeetingSchedule {
  events: CalendarMeeting[];
}

const MeetingSchedule = ({ events }: IMeetingSchedule) => {
  const [translateValue, setTranslateValue] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const finishedMoving = useDebounce(translateValue, 200);

  const scheduleRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);

  const maxTranslateValue = useRef<number>(0);

  const { timeslot, duration, isOverlap, setTimeslot, setIsOverlap } = useBooking();

  const { date } = useDate();

  const durationInHours = duration / (60 * minutesTo.milliseconds);
  const barWidth = (timelineRef.current as HTMLDivElement)?.clientWidth ?? 0;

  const oneHourScale = 150;

  const getTimeFromX = useCallback(
    (xValue: number) => {
      const suggestedTime = new Date(timeslot);

      if (scheduleRef.current?.clientWidth) {
        const percentOfDay = (xValue + scheduleRef.current?.clientWidth / 2) / barWidth;

        // Because positioning is diffing with a few pixels, we need to round to closest minute.
        const time = new Date(
          Math.round((percentOfDay * minutesInOneDay) / minutesTo.milliseconds) *
            minutesTo.milliseconds
        );

        // Need to use the correct timezone offset here...
        suggestedTime.setHours(time.getUTCHours());
        suggestedTime.setMinutes(time.getUTCMinutes());
        suggestedTime.setSeconds(time.getUTCSeconds());

        // Account for date changes
        suggestedTime.setDate(date.getDate());
        suggestedTime.setMonth(date.getMonth());
        suggestedTime.setFullYear(date.getFullYear());
      }

      return suggestedTime;
    },
    [barWidth, timeslot, date]
  );

  const getStartTime = useCallback(() => {
    const percentToday = getPercentOfDay(timeslot);

    if (scheduleRef.current) {
      setTranslateValue(-percentToday * barWidth + scheduleRef.current?.clientWidth / 2);
    }
  }, [barWidth, timeslot]);

  const generateEvents = () => {
    return events.map((event) => {
      const startTime = new Date(event.start.dateTime);
      const endTime = new Date(
        new Date(event.end.dateTime).getTime() -
          startTime.getTimezoneOffset() * minutesTo.milliseconds
      );

      return (
        <Meeting
          key={event.id}
          barWidth={barWidth}
          time={
            new Date(startTime.getTime() - startTime.getTimezoneOffset() * minutesTo.milliseconds)
          }
          length={
            (new Date(event.end.dateTime).getTime() - new Date(event.start.dateTime).getTime()) /
            (60 * minutesTo.milliseconds)
          }
          disabled={true}
          oneHourScale={oneHourScale}
          passedInTime={endTime < new Date()}
          meetingData={event}
        />
      );
    });
  };

  // Event handlers for the mouse down, mouse move, and mouse up events
  const onMouseDown = (event: React.MouseEvent) => {
    // Removing old animation class when dragging is enabled
    setIsAnimating(false);

    // Store the initial position of the mouse when the drag starts
    const initialMousePosition = event.clientX;
    let prevTranslateValue = translateValue + event.clientX - initialMousePosition;

    // Set up a mouse move event listener to track the element's movement
    const onMouseMove = (event: MouseEvent) => {
      // Calculate the element's new position based on the mouse's movement
      prevTranslateValue = translateValue + event.clientX - initialMousePosition;
      // Update the element's position
      setTranslateValue(prevTranslateValue);
    };

    // Set up a mouse up event listener to stop tracking the element's movement
    const onMouseUp = (_e: MouseEvent) => {
      // Remove the mouse move and mouse up event listeners
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      const newDate = getTimeFromX(-prevTranslateValue);
      // INFO: mutates the arguement
      roundDateToTimeSlot(newDate);

      if (scheduleRef.current) {
        const newTranslateValue =
          -getPercentOfDay(newDate) * barWidth + scheduleRef.current?.clientWidth / 2;

        setTranslateValue(newTranslateValue);
      } else {
        setTranslateValue(prevTranslateValue);
      }

      setTimeslot(newDate);

      // Adding animation class when dropping the mouse
      setIsAnimating(true);
    };

    // Add the mouse move and mouse up event listeners
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const onTouchStart = (event: React.TouchEvent) => {
    // Remove animation when moving bar
    setIsAnimating(false);

    const initialTouchPosition = event.changedTouches[0].clientX;
    let prevTranslateValue =
      translateValue + event.changedTouches[0].clientX - initialTouchPosition;

    // Set up a mouse move event listener to track the element's movement
    const onTouchMove = (event: TouchEvent) => {
      // Calculate the element's new position based on the mouse's movement
      prevTranslateValue = translateValue + event.changedTouches[0].clientX - initialTouchPosition;
      // Update the element's position
      setTranslateValue(prevTranslateValue);
    };

    // Set up a mouse up event listener to stop tracking the element's movement
    const onTouchUp = () => {
      // Remove the mouse move and mouse up event listeners
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchUp);

      const newDate = getTimeFromX(-prevTranslateValue);
      // INFO: mutates the arguement
      roundDateToTimeSlot(newDate);

      if (scheduleRef.current) {
        const newTranslateValue =
          -getPercentOfDay(newDate) * barWidth + scheduleRef.current?.clientWidth / 2;

        setTranslateValue(newTranslateValue);
      } else {
        setTranslateValue(prevTranslateValue);
      }

      setTimeslot(newDate);

      // Add animation when snapping
      setIsAnimating(true);
    };

    // Add the mouse move and mouse up event listeners
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', onTouchUp);
  };

  const checkMeetingCollision = useCallback(
    (timeOfDrop: Date) => {
      let isOverlap = false;
      const startTimeCurrent = new Date(timeOfDrop.getTime());

      // Minus 5 to allow for floored value "before" current time.
      const beforeInTime = new Date(new Date().getTime() - minutesTo.milliseconds * 5);

      if (timeOfDrop < beforeInTime) {
        // TODO: Handle error when back in time.
        // Possibly snap to "now"
        setIsOverlap(true);

        return;
      }

      // The check is thinking that there is an overlap when the meetings are lying next to each other,
      // subtract one minute to make sure that doesn't occur.
      const endTimeCurrent = new Date(timeOfDrop.getTime() + duration - minutesTo.milliseconds);

      events.forEach((meeting) => {
        const meetingStartTime = new Date(meeting.start.dateTime);
        const meetingEndTime = new Date(meeting.end.dateTime);

        const startTimeMeeting = new Date(
          meetingStartTime.getTime() - meetingStartTime.getTimezoneOffset() * minutesTo.milliseconds
        );
        const endTimeMeeting = new Date(
          meetingEndTime.getTime() - meetingEndTime.getTimezoneOffset() * minutesTo.milliseconds
        );

        if (
          startTimeMeeting.getTime() < endTimeCurrent.getTime() &&
          endTimeMeeting.getTime() > startTimeCurrent.getTime()
        ) {
          isOverlap = true;
        }
      });

      setIsOverlap(isOverlap);
    },
    [duration, events, setIsOverlap]
  );

  useEffect(() => {
    if (finishedMoving !== 0) {
      checkMeetingCollision(getTimeFromX(-finishedMoving));
    }
  }, [finishedMoving, getTimeFromX, checkMeetingCollision]);

  useLayoutEffect(() => {
    getStartTime();
  }, [getStartTime]);

  useEffect(() => {
    // Set the max translate
    maxTranslateValue.current =
      scheduleRef.current?.scrollWidth && timelineRef.current?.scrollWidth
        ? timelineRef.current?.scrollWidth - scheduleRef.current?.clientWidth
        : 0;
  }, []);

  return (
    <div ref={scheduleRef} className="meetingSchedule-main">
      <Clock timeOverride={timeslot} isOverlap={isOverlap} />
      <div onMouseDown={onMouseDown} onTouchStart={onTouchStart} className="dragContainer">
        <div className="currentEvent">
          <Meeting
            barWidth={barWidth}
            time={new Date(timeslot)}
            length={durationInHours}
            disabled={false}
            overlap={isOverlap}
            maxTranslate={maxTranslateValue.current}
            oneHourScale={oneHourScale}
          />
        </div>
        <InnerTimeline
          translateValue={translateValue}
          timelineRef={timelineRef}
          isAnimating={isAnimating}
        >
          {generateEvents()}
        </InnerTimeline>
      </div>
      <OuterTimeline translateValue={translateValue} isAnimating={isAnimating} />
    </div>
  );
};

export default MeetingSchedule;
