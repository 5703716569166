import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const People = ({ color, width, height }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        d="M8.99996 2C6.37996 2 4.24996 4.13 4.24996 6.75C4.24996 9.32 6.25996 11.4 8.87996 11.49C8.95996 11.48 9.03996 11.48 9.09996 11.49H9.16996C10.3993 11.449 11.5645 10.9315 12.4192 10.0469C13.274 9.16234 13.7512 7.98004 13.75 6.75C13.75 4.13 11.62 2 8.99996 2ZM14.08 14.149C11.29 12.289 6.73996 12.289 3.92996 14.149C2.65996 14.999 1.95996 16.149 1.95996 17.379C1.95996 18.609 2.65996 19.749 3.91996 20.589C5.31996 21.529 7.15996 21.999 8.99996 21.999C10.84 21.999 12.68 21.529 14.08 20.589C15.34 19.739 16.04 18.599 16.04 17.359C16.03 16.129 15.34 14.989 14.08 14.149V14.149ZM19.99 7.338C20.15 9.278 18.77 10.978 16.86 11.208H16.81C16.75 11.208 16.69 11.208 16.64 11.228C15.67 11.278 14.78 10.968 14.11 10.398C15.14 9.478 15.73 8.098 15.61 6.598C15.5413 5.818 15.2765 5.06806 14.84 4.418C15.3639 4.16308 15.9421 4.03947 16.5245 4.05782C17.1069 4.07617 17.6761 4.23595 18.183 4.52335C18.6899 4.81076 19.1193 5.21717 19.4341 5.70753C19.7489 6.19789 19.9397 6.75747 19.99 7.338V7.338Z"
        fill={color}
      />
      <path
        d="M21.988 16.59C21.908 17.56 21.288 18.4 20.248 18.97C19.248 19.52 17.988 19.78 16.738 19.75C17.458 19.1 17.878 18.29 17.958 17.43C18.058 16.19 17.468 15 16.288 14.05C15.618 13.52 14.838 13.1 13.988 12.79C16.198 12.15 18.978 12.58 20.688 13.96C21.608 14.7 22.078 15.63 21.988 16.59V16.59Z"
        fill={color}
      />
    </Svg>
  );
};

export default People;
