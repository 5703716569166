import React, { useRef } from 'react';

import { useRoomAvailability } from '../../providers/roomAvailabilityProvider';
import rooms from '../../rooms.json';
import Pin from './Pin';

import s from './OfficeMap.module.scss';

const OfficeMap = () => {
  const { activeRoomId, setActiveRoomId, isRoomAvailable } = useRoomAvailability();
  const officeMapRef = useRef<HTMLDivElement>(null);

  const onClickOfficeMap = () => {
    setActiveRoomId(null);
  };

  const selectRoom = (event: React.MouseEvent | React.KeyboardEvent, id: string) => {
    event.stopPropagation();
    setActiveRoomId(id === activeRoomId ? null : id);
  };

  return (
    <div className={s.root} ref={officeMapRef} onClick={onClickOfficeMap}>
      {rooms.map(({ id, name, cssSelector }) => (
        <div className={s[cssSelector]} key={id}>
          <Pin
            roomName={name}
            isActive={id === activeRoomId}
            onClick={(e: React.MouseEvent | React.KeyboardEvent) => selectRoom(e, id)}
            isAvailable={isRoomAvailable(id)}
          />
        </div>
      ))}
    </div>
  );
};

export default OfficeMap;
