import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const CalendarOutline = ({ color, width, height }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill={color}
      />
      <path
        d="M9.89002 8.578C9.48002 8.578 9.14002 8.238 9.14002 7.828V5.828C9.14002 5.418 9.48002 5.078 9.89002 5.078C10.3 5.078 10.64 5.418 10.64 5.828V7.828C10.64 8.238 10.31 8.578 9.89002 8.578ZM14.11 8.578C13.7 8.578 13.36 8.238 13.36 7.828V5.828C13.36 5.418 13.7 5.078 14.11 5.078C14.52 5.078 14.86 5.418 14.86 5.828V7.828C14.86 8.238 14.52 8.578 14.11 8.578ZM16.47 10.89H7.52002C7.11002 10.89 6.77002 10.55 6.77002 10.14C6.77002 9.73 7.11002 9.39 7.52002 9.39H16.47C16.6689 9.39 16.8597 9.46902 17.0003 9.60967C17.141 9.75032 17.22 9.94109 17.22 10.14C17.22 10.3389 17.141 10.5297 17.0003 10.6703C16.8597 10.811 16.6689 10.89 16.47 10.89Z"
        fill={color}
      />
      <path
        d="M13.5 18.422H10.5C7.75 18.422 5.75 16.422 5.75 13.672V10.672C5.75 7.922 7.75 5.922 10.5 5.922H13.5C16.25 5.922 18.25 7.922 18.25 10.672V13.672C18.25 16.422 16.25 18.422 13.5 18.422ZM10.5 7.422C8.25 7.422 7.25 9.052 7.25 10.672V13.672C7.25 15.292 8.25 16.922 10.5 16.922H13.5C15.75 16.922 16.75 15.292 16.75 13.672V10.672C16.75 9.052 15.75 7.422 13.5 7.422H10.5Z"
        fill={color}
      />
    </Svg>
  );
};

export default CalendarOutline;
