import minutesTo from '../minutesTo/minutesTo';

/**
 *
 * @param time a Date
 * @returns a string in the format of `HH:MM` representing the date (in the curent timezone)
 */
const parseTimeToString = (time: Date) => {
  return new Date(
    time.getTime() - new Date().getTimezoneOffset() * minutesTo.milliseconds
  ).toLocaleTimeString('sv-SE', {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export default parseTimeToString;
