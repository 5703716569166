import { IconCollectionType, IconName } from '../../types/icon-name';

import Calendar from './components/Calendar';
import CalendarOutline from './components/CalendarOutline';
import CalendarSolid from './components/CalendarSolid';
import CheckmarkOutline from './components/CheckmarkOutline';
import CheckmarkSolid from './components/CheckmarkSolid';
import CheckmarkSolidDuo from './components/CheckmarkSolidDuo';
import ChevronDown from './components/ChevronDown';
import ChevronDownSolid from './components/ChevronDownSolid';
import ChevronUp from './components/ChevronUp';
import ClockSolidDuo from './components/ClockSolidDuo';
import Crosshair from './components/Crosshair';
import Info from './components/Info';
import Minus from './components/Minus';
import People from './components/People';
import Person from './components/Person';
import PinDuo from './components/PinDuo';
import Plus from './components/Plus';
import QuickBook from './components/QuickBook';
import Remove from './components/Remove';
import Search from './components/Search';
import Settings from './components/Settings';
import SignOut from './components/SignOut';
import TrashBin from './components/TrashBin';

/**
 * A somewhat of an HOC that returns the requested icon with given color and size
 * ```
 * // An icon is then retrieved by its name example usage:
 * IconCollection(name, width, height, color, outlineColor?)
 * ```
 */

export const IconCollection = (
  name: IconName,
  width: number,
  height: number,
  color: string,
  outlineColor?: string
) => {
  const props = { width, height, color, outlineColor };

  const list: IconCollectionType = {
    calendar: <Calendar {...props} />,
    calendarOutline: <CalendarOutline {...props} />,
    calendarSolid: <CalendarSolid {...props} />,
    checkmarkOutline: <CheckmarkOutline {...props} />,
    checkmarkSolid: <CheckmarkSolid {...props} />,
    checkmarkSolidDuo: <CheckmarkSolidDuo {...props} />,
    chevronDown: <ChevronDown {...props} />,
    chevronDownSolid: <ChevronDownSolid {...props} />,
    chevronUp: <ChevronUp {...props} />,
    clockSolidDuo: <ClockSolidDuo {...props} />,
    crosshair: <Crosshair {...props} />,
    info: <Info {...props} />,
    minus: <Minus {...props} />,
    people: <People {...props} />,
    person: <Person {...props} />,
    pinDuo: <PinDuo {...props} />,
    plus: <Plus {...props} />,
    quickBook: <QuickBook {...props} />,
    remove: <Remove {...props} />,
    search: <Search {...props} />,
    settings: <Settings {...props} />,
    signOut: <SignOut {...props} />,
    trashBin: <TrashBin {...props} />,
  };

  return list[name];
};
