import { useEffect, useState } from 'react';

/**
 * Hook for checking current breakpoint
 * @info Read more: https://www.webtips.dev/webtips/react-hooks/usebreakpoint-hook-in-react
 * @returns current breakpoint - "**mobile**"" or "**desktop**""
 */
//
const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<'mobile' | 'desktop'>('mobile');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 757) {
        setBreakpoint('mobile');
      } else {
        setBreakpoint('desktop');
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return breakpoint;
};

export default useBreakpoint;
