import { useQuery } from 'react-query';

import rooms from '../rooms.json';
import { minutesTo, timeNowInUTC } from '../utils';
import useMsGraph from './useMsGraph';
import { ScheduleItem } from '@microsoft/microsoft-graph-types';

export type TScheduleItemsRecord = Record<string, ScheduleItem[]>;

const useAllScheduleItems = () => {
  const { getSchedule } = useMsGraph();

  const { data } = useQuery(
    ['getAllSchedule', rooms[0].id],
    getSchedule(
      rooms.map((room) => room.email),
      rooms[0].id
    ),
    {
      refetchInterval: minutesTo.milliseconds * 1,
      refetchIntervalInBackground: true,
    }
  );

  let scheduleItemsRecord: Record<string, ScheduleItem[]> = {};

  data?.value.forEach((item) => {
    const key = item.scheduleId;
    if (key) {
      scheduleItemsRecord[key] =
        item.scheduleItems?.filter(
          (scheduledItem) =>
            scheduledItem.end?.dateTime && new Date(scheduledItem?.end?.dateTime) >= timeNowInUTC()
        ) ?? [];
    }
  });

  return scheduleItemsRecord;
};

export default useAllScheduleItems;
