import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';

import useMsGraph from '../../hooks/useMsGraph';
import { useBooking } from '../../providers/bookingProvider';
import { useDate } from '../../providers/dateProvider';
import { useRoomAvailability } from '../../providers/roomAvailabilityProvider';
import {
  getTimeslotLabel,
  minutesTo,
  parseTimeToString,
  roundDateToTimeSlot,
  spaceOrEnterToClick,
  timeNowInUTC,
} from '../../utils';
import BookButton from '../BookingForm/BookButton';
import Button from '../Button/Button';
import PeopleSymbol from '../PeopleSymbol/PeopleSymbol';
import StatusMessage from '../StatusMessage/StatusMessage';
import classNames from 'classnames';

import s from './Room.module.scss';

const Room = ({ room, isActive }) => {
  const [statusMessage, setStatusMessage] = useState(null);
  const { accounts } = useMsal();
  const { getSchedule } = useMsGraph();
  const { isRoomAvailable, setRoomAvailability } = useRoomAvailability();
  const { defaultDuration } = useBooking();

  useQuery(['getSchedule', room.id], getSchedule([room.email], room.id), {
    refetchInterval: minutesTo.milliseconds * 5,
    refetchIntervalInBackground: true,
    onSuccess: (d) => {
      // INFO: upcomingEvents is UTC!
      const upcommingEvents = d?.value?.[0].scheduleItems.filter(
        (item) => new Date(item.end.dateTime) >= timeNowInUTC()
      );

      if (upcommingEvents.length === 0) {
        setStatusMessage(
          <StatusMessage variant="green" dataCy="status-free">
            Free for the day
          </StatusMessage>
        );

        return setRoomAvailability(room.id, true);
      }

      const startTime = new Date(upcommingEvents[0].start?.dateTime);
      const endTime = new Date(upcommingEvents[0].end?.dateTime);
      const now = timeNowInUTC();

      if (startTime < now && endTime > now) {
        setStatusMessage(
          <StatusMessage variant="red" dataCy="status-busy">{`Busy until  ${parseTimeToString(
            endTime
          )}`}</StatusMessage>
        );

        return setRoomAvailability(room.id, false);
      }

      if (startTime > now) {
        setStatusMessage(
          <StatusMessage
            variant="green"
            dataCy="status-free-until"
          >{`Free until  ${parseTimeToString(startTime)}`}</StatusMessage>
        );

        if (now.getTime() + defaultDuration.value > startTime) {
          return setRoomAvailability(room.id, false);
        }

        setRoomAvailability(room.id, true);
      }
    },
  });
  const { setDate } = useDate();
  // INFO: reset current date in date provider on unmount
  useEffect(() => {
    return setDate(new Date());
  }, [setDate]);

  return (
    <Link
      className={s.link}
      to={`/rooms/${room.id}`}
      data-cy={`${room.id}`}
      onKeyDown={spaceOrEnterToClick}
    >
      <div className={classNames(s.room, isActive && s.active)}>
        <div className={s.roomHeadingContainer}>
          <h4>{room.name}</h4>
          <PeopleSymbol people={room.size} />
        </div>
        {statusMessage}
        {isRoomAvailable(room.id) && !room.isTheEntireOffice ? (
          <BookButton
            timeslot={roundDateToTimeSlot(new Date(), true)}
            duration={defaultDuration.value}
            roomId={room.id}
            roomEmail={room.email}
            roomName={room.name}
            userDisplayName={accounts?.[0]?.name}
            size="medium"
            dataCy="button-book-now"
          >
            {getTimeslotLabel(roundDateToTimeSlot(timeNowInUTC(), true), defaultDuration.value)}
          </BookButton>
        ) : (
          <Button size="medium" color="yellow" dataCy="button-see-schedule">
            See Schedule
          </Button>
        )}
      </div>
    </Link>
  );
};

export default Room;
