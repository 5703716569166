import { createIsoDate } from '../utils';

const getRoomEvents =
  (apiGet) =>
  (roomId, date = new Date()) =>
    apiGet(
      `/users/${roomId}/calendarView?startDateTime=${createIsoDate(
        new Date(date).setHours(0, 0, 0, 0)
      )}&endDateTime=${createIsoDate(new Date(date).setHours(23, 59, 59, 999))}`
    );

export default getRoomEvents;
