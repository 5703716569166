import { getToday } from '../../../functions/helpers/getToday/getToday';

/**
 * Fixture with a booking now for at least 2 hours
 */
export const bookedNow = {
  '@odata.context':
    'https://graph.microsoft.com/v1.0/$metadata#Collection(microsoft.graph.scheduleInformation)',
  value: [
    {
      scheduleId: 'penelopecrewsroom@mpya.se',
      availabilityView:
        '000000000000000000000000000000000000220022202200222200000222000000000000000000002222000000000000',
      scheduleItems: [
        {
          isPrivate: false,
          status: 'busy',
          subject: 'Some meeting',
          location: 'Penelopecrews',
          isMeeting: true,
          isRecurring: false,
          isException: false,
          isReminderSet: false,
          start: {
            dateTime: getToday(new Date().getHours()),
            timeZone: 'UTC',
          },
          end: {
            dateTime: getToday(new Date().getHours() + 2),
            timeZone: 'UTC',
          },
        },
      ],
    },
  ],
};
