import Option from './Option';

import s from './OptionDropdown.module.scss';

interface ISuggestionDropdown {
  data: string[];
  hoveredTarget: number;
  optionLimit?: number;
  isLoading: boolean;
  addChosen: (item: string) => void;
  setHoveredTarget: (idx: number) => void;
}

const OptionDropdown = ({
  data,
  hoveredTarget,
  optionLimit = data.length,
  isLoading,
  addChosen,
  setHoveredTarget,
}: ISuggestionDropdown) => {
  const renderOptions = () => {
    return data.map((item: string, idx: number) => {
      if (idx < optionLimit) {
        return (
          <div
            key={item}
            onMouseOver={() => setHoveredTarget(idx)}
            onClick={() => {
              addChosen(item);
            }}
          >
            <Option item={item} selected={hoveredTarget === idx} />
          </div>
        );
      }

      return undefined;
    });
  };

  return (
    <div className={s.container}>
      {isLoading ? <div className={`${s['spinner-container']} spinner`} /> : renderOptions()}
    </div>
  );
};

export default OptionDropdown;
