export const MS_GRAPH_BASE_URL = 'https://graph.microsoft.com/v1.0';
export const MS_SCHEDULE_AVAILABILITY_VIEW_INTERVAL = 15;

// The maximum booking duration is set to 8 hours
export const DURATIONS = Array.from({ length: 32 }, (_, i) => (i + 1) * 15).map((minutes) => ({
  value: minutes * 60 * 1000,
  label: `${minutes >= 60 ? `${Math.floor(minutes / 60)}h` : ''} ${minutes % 60}min`,
}));

export const STORAGE_DURATION = 'defaultDuration';
