import TextareaAutosize from 'react-textarea-autosize';

import s from './Input.module.scss';

interface IInput {
  value?: string;
  placeholder: string;
  maxRows: number;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = ({ value, placeholder, maxRows = 8, onChange }: IInput) => (
  <div className={s.textArea}>
    <TextareaAutosize
      value={value}
      className={s.inner}
      maxRows={maxRows}
      onChange={(e) => onChange(e)}
      placeholder={placeholder}
      spellCheck="true"
    />
  </div>
);

export default TextArea;
