import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import useMsGraph from '../../hooks/useMsGraph';
import rooms from '../../rooms.json';
import { CalendarMeeting, RoomNames } from '../../types/calendar-event';
import { minutesTo } from '../../utils';
import { getPercentOfDay } from '../../utils/getPercentageOfDay/getPercentageOfDay';
import InnerTimeline from '../MeetingSchedule/InnerTimeline';
import Meeting from '../MeetingSchedule/Meeting';
import OuterTimeline from '../MeetingSchedule/OuterTimeline';
import eventReducer, { EventActionTypes, initialEventState } from './event.reducer';
import classNames from 'classnames';

import s from './Schedule.module.scss';

const Schedule = () => {
  const [events, dispatchEvent] = useReducer(eventReducer, initialEventState);
  const [isAnimating, setIsAnimating] = useState(false);
  const [translateValue, setTranslateValue] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);

  const barWidth = (ref.current as HTMLDivElement)?.clientWidth ?? 0;

  const oneHourScale = 150;

  const { getSchedule } = useMsGraph();

  const { isLoading } = useQuery(
    ['getAllSchedule', rooms[0].id],
    getSchedule(
      rooms.map((room) => room.email),
      rooms[0].id
    ),
    {
      refetchInterval: minutesTo.milliseconds * 1,
      refetchIntervalInBackground: true,
      onSuccess: (d) => {
        dispatchEvent({ type: EventActionTypes.ADD, payload: d.value });
      },
    }
  );

  const generateEvents = (events: CalendarMeeting[]) => {
    return events?.map((event, idx) => {
      const startTime = new Date(event.start.dateTime);
      const endTime = new Date(
        new Date(event.end.dateTime).getTime() -
          startTime.getTimezoneOffset() * minutesTo.milliseconds
      );

      return (
        <Meeting
          key={idx}
          barWidth={barWidth}
          time={
            new Date(startTime.getTime() - startTime.getTimezoneOffset() * minutesTo.milliseconds)
          }
          length={
            (new Date(event.end.dateTime).getTime() - new Date(event.start.dateTime).getTime()) /
            (60 * minutesTo.milliseconds)
          }
          disabled={true}
          schedule={true}
          oneHourScale={oneHourScale}
          passedInTime={endTime < new Date()}
          meetingData={event}
        />
      );
    });
  };

  // TODO: Extract this logic from here, as well as in MeetingSchedule.tsx, to a util.
  const onMouseDown = (event: React.MouseEvent) => {
    // Removing old animation class when dragging is enabled
    setIsAnimating(false);

    // Store the initial position of the mouse when the drag starts
    const initialMousePosition = event.clientX;
    let prevTranslateValue = translateValue + event.clientX - initialMousePosition;
    // Set up a mouse move event listener to track the element's movement
    const onMouseMove = (event: MouseEvent) => {
      // Calculate the element's new position based on the mouse's movement
      prevTranslateValue = translateValue + event.clientX - initialMousePosition;

      // TODO: Might be useful to clamp this more to only show work hours.
      const paddedBounds = 50;
      const timelineLength =
        -(ref.current as HTMLElement)?.clientWidth +
        (timelineRef.current as HTMLDivElement)?.clientWidth;

      // Clamping the translate values to not go out of bounds
      if (prevTranslateValue < timelineLength - paddedBounds) {
        prevTranslateValue = timelineLength - paddedBounds;
      } else if (prevTranslateValue > paddedBounds) {
        prevTranslateValue = paddedBounds;
      }

      // Update the element's position
      setTranslateValue(prevTranslateValue);
    };

    // Set up a mouse up event listener to stop tracking the element's movement
    const onMouseUp = (_e: MouseEvent) => {
      // Remove the mouse move and mouse up event listeners
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      // Adding animation class when dropping the mouse
      setIsAnimating(true);
    };

    // Add the mouse move and mouse up event listeners
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const getStartTime = useCallback(() => {
    const percentToday = getPercentOfDay(new Date());

    if (timelineRef.current) {
      setTranslateValue(-percentToday * barWidth + timelineRef.current?.clientWidth / 2);
    }
  }, [barWidth]);

  useEffect(() => {
    if (!isLoading) {
      getStartTime();
    }
  }, [getStartTime, isLoading]);

  if (isLoading) {
  }

  return (
    <div className={classNames(s.schedule, s.container)}>
      <div className={s.topWrapper}>
        <div className={s.timelineContainer}>
          <OuterTimeline translateValue={translateValue} isAnimating={isAnimating} reverse />
        </div>
      </div>
      <div className={s.contentContainer}>
        <div
          className={s.roomsColumn}
          style={{
            gridTemplateRows: `repeat(${rooms.length}, 40px)`,
          }}
        >
          {rooms.map((room) => (
            <p key={room.id} className={s.roomName}>
              {room.name}
            </p>
          ))}
        </div>

        {isLoading ? (
          <div className={s.spinnerContainer}>
            <span className={s.spinner}></span>
          </div>
        ) : (
          <>
            <div
              ref={timelineRef}
              onMouseDown={onMouseDown}
              className={s.scheduleTimeline}
              style={{
                gridTemplateRows: `repeat(${rooms.length}, 40px)`,
              }}
            >
              {rooms.map((room, idx) => (
                <InnerTimeline
                  key={idx}
                  translateValue={translateValue}
                  isAnimating={isAnimating}
                  timelineRef={ref}
                  dashed={true}
                >
                  <div
                    key={room.id}
                    className={s.clockLine}
                    style={{
                      transform: `translateX(${getPercentOfDay(new Date()) * barWidth}px)`,
                    }}
                  />
                  {generateEvents(events[room.email as RoomNames])}
                </InnerTimeline>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Schedule;
