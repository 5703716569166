import React, { ReactNode, createContext, useContext, useState } from 'react';

interface IDateProvider {
  children?: ReactNode;
}

interface DateContextProps {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  incrementDay: () => void;
  decrementDay: () => void;
}

const DateContext = createContext<DateContextProps | undefined>(undefined);

const DateProvider = ({ children }: IDateProvider) => {
  const [date, setDate] = useState(new Date());

  const incrementDay = () => setDate((d) => new Date(d.setDate(d.getDate() + 1)));

  const decrementDay = () => setDate((d) => new Date(d.setDate(d.getDate() - 1)));

  return (
    <DateContext.Provider value={{ date, setDate, incrementDay, decrementDay }}>
      {children}
    </DateContext.Provider>
  );
};

const useDate = () => {
  const context = useContext(DateContext);

  if (!context) {
    throw new Error('Wrap consumer of useBooking in BookingProvider');
  }

  return context;
};

export { DateProvider, useDate };
