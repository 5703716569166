import { TScheduleItemsRecord } from '../../hooks/useAllScheduleItems';
import rooms from '../../rooms.json';
import createCETDate from '../createCETDate/createCETDate';
import roundDateToTimeSlot from '../roundDateToTimeSlot/roundDateToTimeSlot';
import timeNowInUTC from '../timeNowInUTC/timeNowInUTC';

type TQuickBookRoom = {
  room: any; // Add type for rooms.json ??
  timeslotStart: Date;
};

/**
 *
 * @param roomScheduleRecord { [roomMail]: availabilityView }
 * @param nrAttendees how many attendees the meeting
 * @param durationInMS duration in milliseconds
 * @returns the room to be booked or undefined
 */
const getQuickBookRoom = (
  roomScheduleRecord: TScheduleItemsRecord,
  nrAttendees: number,
  durationInMS: number
): TQuickBookRoom | null => {
  if (nrAttendees <= 0) {
    return null;
  }

  const validRooms = rooms
    .filter(({ isTheEntireOffice }) => !isTheEntireOffice)
    .filter(({ size }) => size >= nrAttendees)
    .sort((a, b) => (a.size < b.size ? -1 : 1));

  const earliestTimeslotList: TQuickBookRoom[] = [];

  for (const room of validRooms) {
    // INFO: upcoming events are in UTC
    const upcomingEvents: any = roomScheduleRecord[room.email];

    const hasUpcomingEvents = upcomingEvents && upcomingEvents.length > 0;
    const availableNow =
      !hasUpcomingEvents ||
      roundDateToTimeSlot(timeNowInUTC(durationInMS), true) <
        new Date(upcomingEvents[0].start.dateTime);

    if (availableNow) {
      earliestTimeslotList.push({ room, timeslotStart: roundDateToTimeSlot(new Date(), true) });
      continue;
    }

    // Check availability in timeslots between meetings
    for (let i = 0; i < upcomingEvents.length - 1; i++) {
      const thisEvent = upcomingEvents[i];
      const nextEvent = upcomingEvents[i + 1];

      const thisEventEnd = new Date(thisEvent.end.dateTime);
      const nextEventStart = new Date(nextEvent.start.dateTime);

      const isTimeslotAvailable = durationInMS <= nextEventStart.getTime() - thisEventEnd.getTime();

      if (isTimeslotAvailable) {
        earliestTimeslotList.push({ room, timeslotStart: createCETDate(thisEventEnd) });
        break;
      }
    }

    const lastEventEnd = new Date(upcomingEvents[upcomingEvents.length - 1].end.dateTime);

    const hasAddedRoom =
      earliestTimeslotList.length > 0 &&
      earliestTimeslotList[earliestTimeslotList.length - 1].room.name === room.name;

    if (!hasAddedRoom) {
      earliestTimeslotList.push({ room, timeslotStart: createCETDate(lastEventEnd) });
    }
  }

  // Sort the earliest timeslot for each room in ascending order,
  // sortedRooms[0] holds the earliest timeslot and room
  const sortedRooms = [...earliestTimeslotList].sort((a, b) =>
    a.timeslotStart < b.timeslotStart ? -1 : 1
  );

  if (sortedRooms.length === 0) {
    return null;
  }

  return sortedRooms[0];
};

export default getQuickBookRoom;
