import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const ChevronDownSolid = ({ color, width, height }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.06 11.27L12.53 14.8C12.38 14.95 12.19 15.02 12 15.02C11.81 15.02 11.62 14.95 11.47 14.8L7.94 11.27C7.80052 11.1289 7.7223 10.9384 7.7223 10.74C7.7223 10.5416 7.80052 10.3511 7.94 10.21C8.23 9.92 8.71 9.92 9 10.21L12 13.21L15 10.21C15.29 9.92 15.77 9.92 16.06 10.21C16.35 10.5 16.35 10.97 16.06 11.27V11.27Z"
        fill={color}
      />
    </Svg>
  );
};

export default ChevronDownSolid;
