import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const CheckmarkSolidDuo = ({ color, outlineColor, width, height }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        opacity="0.4"
        d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
        fill={outlineColor}
      />
      <path
        d="M10.58 15.582C10.3812 15.5818 10.1905 15.5027 10.05 15.362L7.22002 12.532C7.08054 12.3909 7.00232 12.2004 7.00232 12.002C7.00232 11.8036 7.08054 11.6131 7.22002 11.472C7.51002 11.182 7.99002 11.182 8.28002 11.472L10.58 13.772L15.72 8.63199C16.01 8.34199 16.49 8.34199 16.78 8.63199C17.07 8.92199 17.07 9.40199 16.78 9.69199L11.11 15.362C10.9695 15.5027 10.7789 15.5818 10.58 15.582V15.582Z"
        fill={color}
      />
    </Svg>
  );
};

export default CheckmarkSolidDuo;
