import { getToday } from '../../../functions/helpers/getToday/getToday';

/**
 * Fixture with a booking at least and hour from now
 */
export const bookedInTheFuture = {
  '@odata.context':
    'https://graph.microsoft.com/v1.0/$metadata#Collection(microsoft.graph.scheduleInformation)',
  value: [
    {
      scheduleId: 'crewbb@mpya.se',
      availabilityView:
        '000000000000000000000000000000000000222200000000000000000000000000000000000000000000000000000000',
      scheduleItems: [
        {
          isPrivate: false,
          status: 'busy',
          subject: 'Meeting 1',
          location: 'CrewBB',
          isMeeting: true,
          isRecurring: false,
          isException: false,
          isReminderSet: false,
          start: { dateTime: getToday(new Date().getHours() + 2), timeZone: 'UTC' },
          end: { dateTime: getToday(new Date().getHours() + 2, 30), timeZone: 'UTC' },
        },
      ],
      workingHours: {
        daysOfWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
        startTime: '08:00:00.0000000',
        endTime: '17:00:00.0000000',
        timeZone: { name: 'Pacific Standard Time' },
      },
    },
  ],
};
