import { Link } from 'react-router-dom';

import Button from '../../components/Button/Button';

import s from './NotFound.module.scss';

const NotFound = () => (
  <div className={s.container}>
    <h1 data-cy="not-found-title">Page not found</h1>
    <Link to="/" data-cy="return-home-link">
      <Button size="large">Return home</Button>
    </Link>
  </div>
);

export default NotFound;
