import React from 'react';

import { DURATIONS, STORAGE_DURATION } from '../../config';
import { useBooking } from '../../providers/bookingProvider';
import Modal from '../Modal/Modal';

import s from './Settings.module.scss';

const SettingsModal = ({ onChange, value }) => {
  const handleChange = (e) => {
    const selectedTime = DURATIONS.find((time) => time.value === parseInt(e.target.value));
    if (selectedTime) {
      onChange(selectedTime);
    }
  };

  return (
    <div className={s.wrapper} data-cy="settingsModal">
      <label htmlFor="defaultDuration">Default time:</label>
      <select
        id="defaultDuration"
        className={s.select}
        value={value}
        onChange={handleChange}
        data-cy="defaultDurationSelect"
      >
        {DURATIONS.map((time) => (
          <option key={time.label} value={time.value}>
            {time.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const Settings = ({ isOpenModal, setIsOpenModal }) => {
  const { defaultDuration, setDefaultDuration } = useBooking();

  const exit = () => {
    setIsOpenModal(false);
  };

  const saveAndExit = () => {
    localStorage.setItem(STORAGE_DURATION, JSON.stringify(defaultDuration));
    setDefaultDuration(defaultDuration);
    exit();
  };

  return (
    isOpenModal && (
      <Modal
        onClose={saveAndExit}
        onDismiss={exit}
        closeBtnText="Save & exit"
        title="Settings"
        dataCyButton="modalSettingsButton"
      >
        <SettingsModal onChange={setDefaultDuration} value={defaultDuration.value} />
      </Modal>
    )
  );
};

export default Settings;
