import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const Plus = ({ color, width, height }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        d="M6 12H18M12 18V6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Plus;
