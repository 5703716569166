import { ScheduleEvents } from '../../types/calendar-event';
import { ScheduleInformation } from '@microsoft/microsoft-graph-types';

// An enum with all the types of actions to use in our reducer
export enum EventActionTypes {
  ADD = 'ADD',
  CHANGE = 'DECREASE',
}

// An interface for our actions
interface EventAction {
  type: EventActionTypes;
  payload: ScheduleInformation[];
}

const formatEvents = (events: ScheduleInformation[]) => {
  return events.reduce((acc, obj) => {
    const key = Object.values(obj)[0];

    return {
      ...acc,
      [key]: Object.values(obj)[2],
      total: (acc.total ?? 0) + Object.values(obj)[2].length,
    };
  }, {} as ScheduleEvents);
};

export const initialEventState: ScheduleEvents = {
  'Badcrewkor@mpya.se': [],
  'Bosse@mpya.se': [],
  'crewbb@mpya.se': [],
  'joysrus@mpya.se': [],
  'penelopecrewsroom@mpya.se': [],
  'tomcrews@mpya.se': [],
  total: 0,
};

// Our reducer function that uses a switch statement to handle our actions
const eventReducer = (state: ScheduleEvents, action: EventAction) => {
  const { type, payload } = action;
  switch (type) {
    case EventActionTypes.ADD:
      if (payload) {
        return formatEvents(payload);
      }

      return initialEventState;
    default:
      return initialEventState;
  }
};

export default eventReducer;
