import classNames from 'classnames';

import s from './Timeline.module.scss';

interface IOuterTimeline {
  translateValue: number;
  isAnimating: boolean;
  reverse?: boolean;
}

const OuterTimeline = ({ translateValue, isAnimating, reverse = false }: IOuterTimeline) => {
  return (
    <div className={classNames([s.timeline, s.container, reverse ? s.thin : ''])}>
      <div
        className={classNames(s.timeline, s.wrapper, isAnimating ? s.animating : '')}
        style={{
          transform: `translateX(${translateValue}px)`,
        }}
      >
        {[...Array(24)].map((_, index) => {
          return (
            <div
              className={classNames(s.outer, s.hourContainer, reverse ? s.thin : '')}
              key={index}
              onDrag={() => false}
            >
              <div className={s['outer-hourWrapper']}>
                {[...new Array(2)].map((_, idx) =>
                  reverse ? (
                    <div key={idx} className={s.chunkContainer}>
                      {idx % 2 === 0 && (
                        <p className={classNames(s.timeIndicator, s.timestamp)}>{`${index}:00`}</p>
                      )}
                    </div>
                  ) : (
                    <div key={idx} className={s.chunkContainer}>
                      <hr
                        dir="vertical"
                        className={classNames(s.timeIndicator, s.line)}
                        style={{
                          height: idx % 2 === 0 ? '10px' : '5px',
                        }}
                      ></hr>
                      {idx % 2 === 0 && (
                        <p className={classNames(s.timeIndicator, s.timestamp)}>{`${index}:00`}</p>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OuterTimeline;
