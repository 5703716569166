import minutesTo from '../minutesTo/minutesTo';

/**
 * Function to get how many percent of the day that has passed
 */
export const getPercentOfDay = (time: Date) => {
  const now = time;

  // Calculate the number of milliseconds that have passed today
  const timeToday =
    now.getTime() - new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();

  // Calculate the total number of milliseconds in a day
  const msInDay = 24 * 60 * minutesTo.milliseconds;

  // Calculate the percentage of time that has passed today
  return timeToday / msInDay;
};
