import { QueryFunction } from 'react-query';

import { OrgUserResponse } from '../types/organization-user';

const getOrgUsers =
  (apiGet: (url1: string, url2: string) => QueryFunction<OrgUserResponse, string[]>) =>
  (query: string) => {
    return apiGet(
      `/users?$filter=startsWith(displayName, '${query}')`,
      `/users?$filter=startsWith(surName, '${query}')`
    );
  };

export default getOrgUsers;
