import createEvent from '../queries/createEvent';
import deleteEvent from '../queries/deleteEvent';
import getMyEventById from '../queries/getMyEventById';
import getMyEvents from '../queries/getMyEvents';
import getOrgUsers from '../queries/getOrgUsers';
import getRoomEvents from '../queries/getRoomEvents';
import getSchedule from './useGetSchedule';
import useMsGraphCall from './useMsGraphCall';

const useMsGraph = () => {
  const { apiGet, apiGetWithFallback, apiPost, apiDelete } = useMsGraphCall();

  return {
    getMyEventById: getMyEventById(apiGet),
    getRoomEvents: getRoomEvents(apiGet),
    getMyEvents: getMyEvents(apiGet),
    getSchedule,
    getOrgUsers: getOrgUsers(apiGetWithFallback),
    createEvent: createEvent(apiPost),
    deleteEvent: deleteEvent(apiDelete),
  };
};

export default useMsGraph;
