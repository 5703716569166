import React from 'react';

import arrowLeftIconPath from '../../assets/arrow-left.svg';
import arrowRightIconPath from '../../assets/arrow-right.svg';
import minusIconPath from '../../assets/minus.svg';
import plusIconPath from '../../assets/plus.svg';
import classNames from 'classnames';

import s from './Input.module.scss';

type TSize = 'small' | 'medium';

interface IInputRange {
  value: string;
  onIncrement: React.MouseEventHandler<HTMLButtonElement>;
  onDecrement: React.MouseEventHandler<HTMLButtonElement>;
  iconType: 'arrows' | 'operators';
  isIncrementDisabled?: boolean;
  isDecrementDisabled?: boolean;
  size?: TSize;
  iconSuffix?: React.ReactNode;
  dataCy?: string;
  tabIndex?: number;
}

const InputRange = ({
  value,
  onIncrement,
  onDecrement,
  isDecrementDisabled,
  isIncrementDisabled,
  iconType = 'arrows',
  size = 'medium',
  iconSuffix,
  dataCy,
  tabIndex,
}: IInputRange) => {
  let leftButtonPath = arrowLeftIconPath;
  let rightButtonPath = arrowRightIconPath;

  if (iconType === 'operators') {
    leftButtonPath = minusIconPath;
    rightButtonPath = plusIconPath;
  }

  return (
    <div className={s.inputRange} data-cy={dataCy}>
      <button
        className={classNames(s.inputButton, s[size])}
        onClick={onDecrement}
        disabled={isDecrementDisabled}
        tabIndex={tabIndex}
      >
        <img src={leftButtonPath} alt="Left button" />
      </button>
      <div className={classNames(s.inputValueContainer, s[size])}>
        <div>
          <span>{value}</span>
        </div>
        {iconSuffix && <div className={s.inputValueIcon}>{iconSuffix}</div>}
      </div>
      <button
        className={classNames(s.inputButton, s[size])}
        onClick={onIncrement}
        disabled={isIncrementDisabled}
        tabIndex={tabIndex}
      >
        <img src={rightButtonPath} alt="Right button" />
      </button>
    </div>
  );
};

export default InputRange;
