import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { compareEmailAddresses } from '../utils';
import useMsGraph from './useMsGraph';

const usePollEventForAcceptance = ({ roomEmail, roomId }) => {
  const [refetchInterval, setRefetchInterval] = useState(1000);
  const [eventId, setEventId] = useState();
  const [accepted, setAccepted] = useState();
  const [pollIndex, setPollindex] = useState(0);
  const [isPolling, setIsPolling] = useState(false);
  const queryClient = useQueryClient();

  const { getMyEventById } = useMsGraph();

  const { error } = useQuery(['getMyEventById', eventId], getMyEventById(eventId), {
    refetchIntervalInBackground: true,
    refetchInterval,
    onSuccess: (data) => {
      setPollindex((index) => index + 1);
      const attendeeResponse = data?.attendees?.find((attendee) =>
        compareEmailAddresses(attendee.emailAddress.address, roomEmail)
      )?.status.response;

      if (attendeeResponse === 'accepted' || attendeeResponse === 'declined') {
        setAccepted(attendeeResponse === 'accepted');
        queryClient.invalidateQueries('getMyEvents');
        queryClient.invalidateQueries('getRoomEvents');
        queryClient.invalidateQueries(['getSchedule', roomId]);
        stopPolling();
      } else if (!attendeeResponse) {
        stopPolling();
      } else {
        setRefetchInterval(Math.max(2000 - pollIndex * 1000, 500));
      }
    },
    onError: () => setRefetchInterval(false),
    enabled: Boolean(eventId),
    cacheTime: 0,
  });

  const startPolling = (id) => {
    setEventId(id);
    setAccepted(undefined);
    setIsPolling(true);
  };
  const stopPolling = () => {
    setEventId(undefined);
    setPollindex(0);
    setIsPolling(false);
    setRefetchInterval(false);
  };

  return { accepted, startPolling, stopPolling, error, isPolling };
};

export default usePollEventForAcceptance;
