import { getToday } from '../../functions/helpers/getToday/getToday';

export const usersEvents = {
  '@odata.context':
    "https://graph.microsoft.com/v1.0/$metadata#users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/events",
  value: [
    {
      '@odata.etag': 'W/"GcnCnBqfxkO741CIBB7NwQAB8X9sDA=="',
      id: 'AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB8WFYtQAAAA==',
      createdDateTime: '2022-11-23T14:31:32.4081221Z',
      lastModifiedDateTime: '2022-11-23T14:31:37.7046703Z',
      changeKey: 'GcnCnBqfxkO741CIBB7NwQAB8X9sDA==',
      categories: [],
      transactionId: null,
      originalStartTimeZone: 'UTC',
      originalEndTimeZone: 'UTC',
      iCalUId:
        '040000008200E00074C5B7101A82E00800000000C3FC544648FFD801000000000000000010000000CCBF7EB6BD4F01448F5EBA7269FA1904',
      reminderMinutesBeforeStart: 15,
      isReminderOn: false,
      hasAttachments: false,
      subject: 'Some important meeting',
      bodyPreview: '',
      importance: 'normal',
      sensitivity: 'normal',
      isAllDay: false,
      isCancelled: false,
      isOrganizer: false,
      responseRequested: true,
      seriesMasterId: null,
      showAs: 'busy',
      type: 'singleInstance',
      webLink:
        'https://outlook.office365.com/owa/?itemid=AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB8WFYtQAAAA%3D%3D&exvsurl=1&path=/calendar/item',
      onlineMeetingUrl: null,
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness',
      allowNewTimeProposals: true,
      occurrenceId: null,
      isDraft: false,
      hideAttendees: false,
      responseStatus: {
        response: 'accepted',
        time: '2022-11-23T14:31:36.7984727Z',
      },
      body: {
        contentType: 'html',
        content:
          '<html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">\r\n<meta name="Generator" content="Microsoft Exchange Server">\r\n<!-- converted from text -->\r\n<style><!-- .EmailQuote { margin-left: 1pt; padding-left: 4pt; border-left: #800000 2px solid; } --></style></head>\r\n<body>\r\n<font size="2"><span style="font-size:11pt;"><div class="PlainText">&nbsp;</div></span></font>\r\n</body>\r\n</html>\r\n',
      },
      start: {
        dateTime: getToday(19),
        timeZone: 'UTC',
      },
      end: {
        dateTime: getToday(20),
        timeZone: 'UTC',
      },
      location: {
        displayName: 'Penelopecrews',
        locationType: 'default',
        uniqueId: 'Penelopecrews',
        uniqueIdType: 'private',
      },
      locations: [
        {
          displayName: 'Penelopecrews',
          locationType: 'default',
          uniqueId: 'Penelopecrews',
          uniqueIdType: 'private',
        },
      ],
      recurrence: null,
      attendees: [
        {
          type: 'required',
          status: {
            response: 'none',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Abe Lincoln',
            address: 'abe.lincoln@president.com',
          },
        },
        {
          type: 'optional',
          status: {
            response: 'accepted',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Penélope Crews',
            address: 'penelopecrews@mpyadigital.com',
          },
        },
      ],
      organizer: {
        emailAddress: {
          name: 'Abe Lincoln',
          address: 'abe.lincoln@president.com',
        },
      },
      onlineMeeting: {
        joinUrl:
          'https://teams.microsoft.com/l/meetup-join/19%3ameeting_MzBlOTUyZmItYTFkYi00OWYzLWE3YjctYWE5MGI5MzI0MDk5%40thread.v2/0?context=%7b%22Tid%22%3a%22aea5ed0a-d315-4fb1-b4f3-9137000c093b%22%2c%22Oid%22%3a%22d778c998-6da7-4a35-94ad-ef520d2f810e%22%7d',
      },
      'calendar@odata.associationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')/$ref",
      'calendar@odata.navigationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')",
    },
    {
      '@odata.etag': 'W/"GcnCnBqfxkO741CIBB7NwQAB8X9ngA=="',
      id: 'AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB8WFYsgAAAA==',
      createdDateTime: '2022-11-23T13:21:25.6526441Z',
      lastModifiedDateTime: '2022-11-23T13:22:27.3207155Z',
      changeKey: 'GcnCnBqfxkO741CIBB7NwQAB8X9ngA==',
      categories: [],
      transactionId: null,
      originalStartTimeZone: 'UTC',
      originalEndTimeZone: 'UTC',
      iCalUId:
        '040000008200E00074C5B7101A82E00800000000BB790C7B3EFFD801000000000000000010000000C2CE297B4A5B94498E99F969FBA2C71E',
      reminderMinutesBeforeStart: 15,
      isReminderOn: false,
      hasAttachments: false,
      subject: 'Another three',
      bodyPreview: '',
      importance: 'normal',
      sensitivity: 'normal',
      isAllDay: false,
      isCancelled: false,
      isOrganizer: false,
      responseRequested: true,
      seriesMasterId: null,
      showAs: 'busy',
      type: 'singleInstance',
      webLink:
        'https://outlook.office365.com/owa/?itemid=AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB8WFYsgAAAA%3D%3D&exvsurl=1&path=/calendar/item',
      onlineMeetingUrl: null,
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness',
      allowNewTimeProposals: true,
      occurrenceId: null,
      isDraft: false,
      hideAttendees: false,
      responseStatus: {
        response: 'accepted',
        time: '2022-11-23T13:21:28.0587425Z',
      },
      body: {
        contentType: 'html',
        content:
          '<html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">\r\n<meta name="Generator" content="Microsoft Exchange Server">\r\n<!-- converted from text -->\r\n<style><!-- .EmailQuote { margin-left: 1pt; padding-left: 4pt; border-left: #800000 2px solid; } --></style></head>\r\n<body>\r\n<font size="2"><span style="font-size:11pt;"><div class="PlainText">&nbsp;</div></span></font>\r\n</body>\r\n</html>\r\n',
      },
      start: {
        dateTime: getToday(13, 20),
        timeZone: 'UTC',
      },
      end: {
        dateTime: getToday(13, 50),
        timeZone: 'UTC',
      },
      location: {
        displayName: 'Penelopecrews',
        locationType: 'default',
        uniqueId: 'Penelopecrews',
        uniqueIdType: 'private',
      },
      locations: [
        {
          displayName: 'Penelopecrews',
          locationType: 'default',
          uniqueId: 'Penelopecrews',
          uniqueIdType: 'private',
        },
      ],
      recurrence: null,
      attendees: [
        {
          type: 'required',
          status: {
            response: 'none',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Abe Lincoln',
            address: 'abe.lincoln@president.com',
          },
        },
        {
          type: 'resource',
          status: {
            response: 'accepted',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Penélope Crews',
            address: 'penelopecrews@mpyadigital.com',
          },
        },
      ],
      organizer: {
        emailAddress: {
          name: 'Abe Lincoln',
          address: 'abe.lincoln@president.com',
        },
      },
      onlineMeeting: {
        joinUrl:
          'https://teams.microsoft.com/l/meetup-join/19%3ameeting_YWVjMjU0YjctOWE3Mi00ZmY1LTgzYzctYjZmMTE2YmI0OTdh%40thread.v2/0?context=%7b%22Tid%22%3a%22aea5ed0a-d315-4fb1-b4f3-9137000c093b%22%2c%22Oid%22%3a%22db3a0406-99d1-4a91-94fa-cdfb27000662%22%7d',
      },
      'calendar@odata.associationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')/$ref",
      'calendar@odata.navigationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')",
    },
    {
      '@odata.etag': 'W/"GcnCnBqfxkO741CIBB7NwQAB8X9doQ=="',
      id: 'AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB8WFYsQAAAA==',
      createdDateTime: '2022-11-23T09:49:15.139934Z',
      lastModifiedDateTime: '2022-11-23T09:49:18.2803649Z',
      changeKey: 'GcnCnBqfxkO741CIBB7NwQAB8X9doQ==',
      categories: [],
      transactionId: null,
      originalStartTimeZone: 'W. Europe Standard Time',
      originalEndTimeZone: 'W. Europe Standard Time',
      iCalUId:
        '040000008200E00074C5B7101A82E00800000000BB048ED720FFD801000000000000000010000000C50CEEAFF09F624D9F1E3ADB6041A48E',
      reminderMinutesBeforeStart: 15,
      isReminderOn: false,
      hasAttachments: false,
      subject: 'Another two',
      bodyPreview: '',
      importance: 'normal',
      sensitivity: 'normal',
      isAllDay: false,
      isCancelled: false,
      isOrganizer: false,
      responseRequested: false,
      seriesMasterId: null,
      showAs: 'busy',
      type: 'singleInstance',
      webLink:
        'https://outlook.office365.com/owa/?itemid=AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB8WFYsQAAAA%3D%3D&exvsurl=1&path=/calendar/item',
      onlineMeetingUrl: '',
      isOnlineMeeting: false,
      onlineMeetingProvider: 'unknown',
      allowNewTimeProposals: true,
      occurrenceId: null,
      isDraft: false,
      hideAttendees: false,
      responseStatus: {
        response: 'accepted',
        time: '2022-11-23T09:49:17.4210383Z',
      },
      body: {
        contentType: 'html',
        content:
          '<html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">\r\n<meta name="Generator" content="Microsoft Exchange Server">\r\n<!-- converted from text -->\r\n<style><!-- .EmailQuote { margin-left: 1pt; padding-left: 4pt; border-left: #800000 2px solid; } --></style></head>\r\n<body>\r\n<font size="2"><span style="font-size:11pt;"><div class="PlainText">&nbsp;</div></span></font>\r\n</body>\r\n</html>\r\n',
      },
      start: {
        dateTime: getToday(10),
        timeZone: 'UTC',
      },
      end: {
        dateTime: getToday(10, 30),
        timeZone: 'UTC',
      },
      location: {
        displayName: 'Penélope Crews',
        locationUri: 'penelopecrews@mpyadigital.com',
        locationType: 'conferenceRoom',
        uniqueId: 'penelopecrews@mpyadigital.com',
        uniqueIdType: 'directory',
        address: {
          street: '',
          city: '',
          state: '',
          countryOrRegion: '',
          postalCode: '',
        },
        coordinates: {},
      },
      locations: [
        {
          displayName: 'Penélope Crews',
          locationUri: 'penelopecrews@mpyadigital.com',
          locationType: 'conferenceRoom',
          uniqueId: 'penelopecrews@mpyadigital.com',
          uniqueIdType: 'directory',
          address: {
            street: '',
            city: '',
            state: '',
            countryOrRegion: '',
            postalCode: '',
          },
          coordinates: {},
        },
      ],
      recurrence: null,
      attendees: [
        {
          type: 'required',
          status: {
            response: 'none',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Abe Lincoln',
            address: 'abe.lincoln@president.com',
          },
        },
        {
          type: 'optional',
          status: {
            response: 'accepted',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Penélope Crews',
            address: 'penelopecrews@mpyadigital.com',
          },
        },
      ],
      organizer: {
        emailAddress: {
          name: 'Abe Lincoln',
          address: 'abe.lincoln@president.com',
        },
      },
      onlineMeeting: null,
      'calendar@odata.associationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')/$ref",
      'calendar@odata.navigationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')",
    },
    {
      '@odata.etag': 'W/"GcnCnBqfxkO741CIBB7NwQAB8X9alA=="',
      id: 'AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB8WFYsAAAAA==',
      createdDateTime: '2022-11-23T07:50:55.4847323Z',
      lastModifiedDateTime: '2022-11-23T08:01:24.3053206Z',
      changeKey: 'GcnCnBqfxkO741CIBB7NwQAB8X9alA==',
      categories: [],
      transactionId: null,
      originalStartTimeZone: 'UTC',
      originalEndTimeZone: 'UTC',
      iCalUId:
        '040000008200E00074C5B7101A82E00800000000FD53C04E10FFD801000000000000000010000000B112A50AFE2270499078A1E52D879B9C',
      reminderMinutesBeforeStart: 15,
      isReminderOn: false,
      hasAttachments: false,
      subject: 'Another one',
      bodyPreview: '',
      importance: 'normal',
      sensitivity: 'normal',
      isAllDay: false,
      isCancelled: false,
      isOrganizer: false,
      responseRequested: true,
      seriesMasterId: null,
      showAs: 'busy',
      type: 'singleInstance',
      webLink:
        'https://outlook.office365.com/owa/?itemid=AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB8WFYsAAAAA%3D%3D&exvsurl=1&path=/calendar/item',
      onlineMeetingUrl: null,
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness',
      allowNewTimeProposals: true,
      occurrenceId: null,
      isDraft: false,
      hideAttendees: false,
      responseStatus: {
        response: 'accepted',
        time: '2022-11-23T07:50:57.4377316Z',
      },
      body: {
        contentType: 'html',
        content:
          '<html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">\r\n<meta name="Generator" content="Microsoft Exchange Server">\r\n<!-- converted from text -->\r\n<style><!-- .EmailQuote { margin-left: 1pt; padding-left: 4pt; border-left: #800000 2px solid; } --></style></head>\r\n<body>\r\n<font size="2"><span style="font-size:11pt;"><div class="PlainText">&nbsp;</div></span></font>\r\n</body>\r\n</html>\r\n',
      },
      start: {
        dateTime: getToday(8),
        timeZone: 'UTC',
      },
      end: {
        dateTime: getToday(8, 30),
        timeZone: 'UTC',
      },
      location: {
        displayName: 'Penelopecrews',
        locationType: 'default',
        uniqueId: 'Penelopecrews',
        uniqueIdType: 'private',
      },
      locations: [
        {
          displayName: 'Penelopecrews',
          locationType: 'default',
          uniqueId: 'Penelopecrews',
          uniqueIdType: 'private',
        },
      ],
      recurrence: null,
      attendees: [
        {
          type: 'required',
          status: {
            response: 'none',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Abe Lincoln',
            address: 'abe.lincoln@president.com',
          },
        },
        {
          type: 'resource',
          status: {
            response: 'accepted',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Penélope Crews',
            address: 'penelopecrews@mpyadigital.com',
          },
        },
      ],
      organizer: {
        emailAddress: {
          name: 'Abe Lincoln',
          address: 'abe.lincoln@president.com',
        },
      },
      onlineMeeting: {
        joinUrl:
          'https://teams.microsoft.com/l/meetup-join/19%3ameeting_MGQxZjNjNmItMDM0Mi00ZmJjLWE0MDktODA4NjNkZjFmYmVl%40thread.v2/0?context=%7b%22Tid%22%3a%22aea5ed0a-d315-4fb1-b4f3-9137000c093b%22%2c%22Oid%22%3a%22cfd97d06-af15-462c-9d65-77cbb12c499b%22%7d',
      },
      'calendar@odata.associationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')/$ref",
      'calendar@odata.navigationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')",
    },
    {
      '@odata.etag': 'W/"GcnCnBqfxkO741CIBB7NwQAB8X9ahQ=="',
      id: 'AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB8WFYrgAAAA==',
      createdDateTime: '2022-11-23T07:46:01.3779492Z',
      lastModifiedDateTime: '2022-11-23T07:55:45.8262354Z',
      changeKey: 'GcnCnBqfxkO741CIBB7NwQAB8X9ahQ==',
      categories: [],
      transactionId: null,
      originalStartTimeZone: 'UTC',
      originalEndTimeZone: 'UTC',
      iCalUId:
        '040000008200E00074C5B7101A82E00800000000678A67A00FFFD801000000000000000010000000F80D224DB9245546BE878D7E7A78A41B',
      reminderMinutesBeforeStart: 15,
      isReminderOn: false,
      hasAttachments: false,
      subject: 'Another meeting',
      bodyPreview: '',
      importance: 'normal',
      sensitivity: 'normal',
      isAllDay: false,
      isCancelled: false,
      isOrganizer: false,
      responseRequested: true,
      seriesMasterId: null,
      showAs: 'busy',
      type: 'singleInstance',
      webLink:
        'https://outlook.office365.com/owa/?itemid=AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB8WFYrgAAAA%3D%3D&exvsurl=1&path=/calendar/item',
      onlineMeetingUrl: null,
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness',
      allowNewTimeProposals: true,
      occurrenceId: null,
      isDraft: false,
      hideAttendees: false,
      responseStatus: {
        response: 'accepted',
        time: '2022-11-23T07:46:03.5809284Z',
      },
      body: {
        contentType: 'html',
        content:
          '<html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">\r\n<meta name="Generator" content="Microsoft Exchange Server">\r\n<!-- converted from text -->\r\n<style><!-- .EmailQuote { margin-left: 1pt; padding-left: 4pt; border-left: #800000 2px solid; } --></style></head>\r\n<body>\r\n<font size="2"><span style="font-size:11pt;"><div class="PlainText">&nbsp;</div></span></font>\r\n</body>\r\n</html>\r\n',
      },
      start: {
        dateTime: getToday(9),
        timeZone: 'UTC',
      },
      end: {
        dateTime: getToday(9, 45),
        timeZone: 'UTC',
      },
      location: {
        displayName: 'Penelopecrews',
        locationType: 'default',
        uniqueId: 'Penelopecrews',
        uniqueIdType: 'private',
      },
      locations: [
        {
          displayName: 'Penelopecrews',
          locationType: 'default',
          uniqueId: 'Penelopecrews',
          uniqueIdType: 'private',
        },
      ],
      recurrence: null,
      attendees: [
        {
          type: 'required',
          status: {
            response: 'none',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Abe Lincoln',
            address: 'abe.lincoln@president.com',
          },
        },
        {
          type: 'resource',
          status: {
            response: 'accepted',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Penélope Crews',
            address: 'penelopecrews@mpyadigital.com',
          },
        },
      ],
      organizer: {
        emailAddress: {
          name: 'Abe Lincoln',
          address: 'abe.lincoln@president.com',
        },
      },
      onlineMeeting: {
        joinUrl:
          'https://teams.microsoft.com/l/meetup-join/19%3ameeting_Y2EyZGE0ZjEtMDNhOC00MzI1LWE0NjItYjQ0ZjAwODFmMWM5%40thread.v2/0?context=%7b%22Tid%22%3a%22aea5ed0a-d315-4fb1-b4f3-9137000c093b%22%2c%22Oid%22%3a%227126123a-5d85-4d3a-8a1c-20c5953daaa0%22%7d',
      },
      'calendar@odata.associationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')/$ref",
      'calendar@odata.navigationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')",
    },
    {
      '@odata.etag': 'W/"GcnCnBqfxkO741CIBB7NwQAB8X76Ig=="',
      id: 'AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB6cONzwAAAA==',
      createdDateTime: '2022-11-10T11:48:50.1765684Z',
      lastModifiedDateTime: '2022-11-21T07:59:26.5897804Z',
      changeKey: 'GcnCnBqfxkO741CIBB7NwQAB8X76Ig==',
      categories: [],
      transactionId: null,
      originalStartTimeZone: 'W. Europe Standard Time',
      originalEndTimeZone: 'W. Europe Standard Time',
      iCalUId:
        '040000008200E00074C5B7101A82E008000000001EA6F063FAF4D801000000000000000010000000AB85A73898FFD3468F5AE12264649C60',
      reminderMinutesBeforeStart: 15,
      isReminderOn: false,
      hasAttachments: false,
      subject: 'Some meeting',
      bodyPreview: '',
      importance: 'normal',
      sensitivity: 'normal',
      isAllDay: false,
      isCancelled: false,
      isOrganizer: false,
      responseRequested: true,
      seriesMasterId: null,
      showAs: 'busy',
      type: 'singleInstance',
      webLink:
        'https://outlook.office365.com/owa/?itemid=AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgBGAAADuhf5VBBC1kSxfDYsnLy2xQcAGcnCnBqfxkO741CIBB7NwQAAAgENAAAAGcnCnBqfxkO741CIBB7NwQAB6cONzwAAAA%3D%3D&exvsurl=1&path=/calendar/item',
      onlineMeetingUrl: null,
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness',
      allowNewTimeProposals: true,
      occurrenceId: null,
      isDraft: false,
      hideAttendees: false,
      responseStatus: {
        response: 'accepted',
        time: '2022-11-10T11:48:52.1920591Z',
      },
      body: {
        contentType: 'html',
        content:
          '<html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">\r\n<meta name="Generator" content="Microsoft Exchange Server">\r\n<!-- converted from text -->\r\n<style><!-- .EmailQuote { margin-left: 1pt; padding-left: 4pt; border-left: #800000 2px solid; } --></style></head>\r\n<body>\r\n<font size="2"><span style="font-size:11pt;"><div class="PlainText">&nbsp;</div></span></font>\r\n</body>\r\n</html>\r\n',
      },
      start: {
        dateTime: getToday(11),
        timeZone: 'UTC',
      },
      end: {
        dateTime: getToday(12),
        timeZone: 'UTC',
      },
      location: {
        displayName: 'Penélope Crews',
        locationUri: 'penelopecrews@mpyadigital.com',
        locationType: 'conferenceRoom',
        uniqueId: 'penelopecrews@mpyadigital.com',
        uniqueIdType: 'directory',
        address: {
          street: '',
          city: '',
          state: '',
          countryOrRegion: '',
          postalCode: '',
        },
        coordinates: {},
      },
      locations: [
        {
          displayName: 'Penélope Crews',
          locationUri: 'penelopecrews@mpyadigital.com',
          locationType: 'conferenceRoom',
          uniqueId: 'penelopecrews@mpyadigital.com',
          uniqueIdType: 'directory',
          address: {
            street: '',
            city: '',
            state: '',
            countryOrRegion: '',
            postalCode: '',
          },
          coordinates: {},
        },
      ],
      recurrence: null,
      attendees: [
        {
          type: 'required',
          status: {
            response: 'none',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Abe Lincoln',
            address: 'abe.lincoln@president.com',
          },
        },
        {
          type: 'required',
          status: {
            response: 'accepted',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'George Washington',
            address: 'george.washington@president.com',
          },
        },
        {
          type: 'resource',
          status: {
            response: 'accepted',
            time: '0001-01-01T00:00:00Z',
          },
          emailAddress: {
            name: 'Penélope Crews',
            address: 'penelopecrews@mpyadigital.com',
          },
        },
      ],
      organizer: {
        emailAddress: {
          name: 'Abe Lincoln',
          address: 'abe.lincoln@president.com',
        },
      },
      onlineMeeting: {
        joinUrl:
          'https://teams.microsoft.com/l/meetup-join/19%3ameeting_YTA1YjlhNjQtOTQ4Zi00MmQ5LWE2MmUtNDU2MGI3YmI4NjIx%40thread.v2/0?context=%7b%22Tid%22%3a%22aea5ed0a-d315-4fb1-b4f3-9137000c093b%22%2c%22Oid%22%3a%227ce63c77-5bc5-408f-a15e-ef443c8635d7%22%7d',
      },
      'calendar@odata.associationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')/$ref",
      'calendar@odata.navigationLink':
        "https://graph.microsoft.com/v1.0/users('ae21a914-c12c-41cf-bbad-adf3ebfbc5a8')/calendars('AQMkADg2MzdiYzBiLTVjNmUtNDYzNi05NjI3LTBhZDZkMmY1NjJlMgAuAAADuhf5VBBC1kSxfDYsnLy2xQEAGcnCnBqfxkO741CIBB7NwQAAAgENAAAA')",
    },
  ],
};
