import s from './Input.module.scss';

interface IInput {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}

const Input = ({ value, onChange, placeholder }: IInput) => (
  <input value={value} className={s.inputText} onChange={onChange} placeholder={placeholder} />
);

export default Input;
