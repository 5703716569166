import s from './OptionDropdown.module.scss';

interface IOption {
  item: string;
  selected: boolean;
}

const Option = ({ item, selected }: IOption) => {
  return (
    <div className={`${s.option} ${selected ? s.selected : ''}`}>
      <p className={s.text}>{item}</p>
      <div className={s.avatar}>
        {item
          .split(' ')
          .map((name) => name[0])
          .join('')
          .substring(0, 2)
          .toUpperCase()}
      </div>
    </div>
  );
};

export default Option;
