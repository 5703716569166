/** Based on the names of the rooms in outlook.
 * If rooms start appearing in the Attendee list, they have been changed in outlook.
 */
export const ROOM = {
  CrewBB: 'crewbb@mpya.se',
  Penelopecrews: 'penelopecrews@mpyadigital.com',
  'Tom Crews': 'tomcrews@mpyadigital.com',
  Bosse: 'Bosse@mpya.se',
  'Joys ”R” Us': 'joysrus@mpyadigital.com',
  Badcrewkor: 'Badcrewkor@mpya.se',
};
