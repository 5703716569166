import { useMutation } from 'react-query';

import useMsGraph from '../../hooks/useMsGraph';
import usePollForDeletion from '../../hooks/usePollForDeletion';
import rooms from '../../rooms';
import { minutesTo, timeNowInUTC } from '../../utils';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

import s from './OngoingMeeting.module.scss';

const OngoingMeeting = ({ event }) => {
  const { deleteEvent } = useMsGraph();
  const { isPolling, startPolling, stopPolling } = usePollForDeletion({
    roomId: rooms.find(({ name }) => name === event?.location?.displayName)?.id,
    iCalUId: event?.iCalUId,
  });
  const { mutate: deleteEventMutation, isLoading: mutationIsLoading } = useMutation(
    deleteEvent(event?.id),
    {
      onSuccess: () => {
        startPolling(event.id);
      },
      onError: (error) => {
        stopPolling();
        alert(error);
      },
    }
  );

  const endMeeting = () => {
    if (
      window.confirm(
        '⚠ You are about to end a meeting and thereby delete it from the calendar.\nAre you sure you want to do this?'
      )
    ) {
      deleteEventMutation();
    }
  };

  const isLoading = mutationIsLoading || isPolling;

  return (
    <>
      {event?.id && (
        <div className={s.root}>
          <div className={s.eventIndicator} />
          <div className={s.innerContainer}>
            <h2 className={s.title}>Ongoing meeting in {event.location.displayName}</h2>
            <p className={s.text}>
              Time remaining{' '}
              <strong>
                {Math.round(
                  (new Date(event.end.dateTime) - timeNowInUTC()) / minutesTo.milliseconds
                )}{' '}
                minutes
              </strong>
              .
            </p>
          </div>
          <div className={s.btnWrapper}>
            <Button size="small" color="red" onClick={endMeeting} loading={isLoading}>
              <Icon name="trashBin" size="sm"></Icon>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default OngoingMeeting;
