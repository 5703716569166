import { IconCollection } from '../../assets/Icons/Collection';
import { IconName } from '../../types/icon-name';

import s from './Icon.module.scss';

interface IIcon {
  name: IconName;
  size?: 'sm' | 'md' | 'lg';
  color?: string;
  outlineColor?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

const SIZE = 24;

const Icon = ({
  name,
  size = 'md',
  color = 'black',
  outlineColor = '#A5A5A5',
  onClick,
  className,
  style,
}: IIcon) => {
  const getSize = () => {
    if (size === 'lg') return 2;

    if (size === 'md') return 1;

    return 1 / 2;
  };

  const renderIcon = () => {
    const iconSize = getSize() * SIZE;
    const icon = IconCollection(name, iconSize, iconSize, color, outlineColor);

    if (!icon) throw new Error(`${name} is not a valid icon name`);

    return icon;
  };

  return (
    <span
      className={`${s['icon-container']} ${className}`}
      style={style}
      onClick={() => onClick && onClick()}
    >
      {renderIcon()}
    </span>
  );
};

export default Icon;
