import { ReactNode } from 'react';

import Icon from '../Icon/Icon';

import s from './Pill.module.scss';

interface IPill {
  children: ReactNode;
  color?: 'default' | 'highlight' | 'disabled';
  deletable?: boolean;
  minWidth?: boolean;
  onDeleteClick?: () => void;
}

const Pill = ({
  children,
  color = 'default',
  deletable = false,
  minWidth = false,
  onDeleteClick,
}: IPill) => {
  return (
    <div className={`${s.pill} ${s[color]} ${minWidth && s.minWidth}`}>
      {children}
      {deletable && (
        <div className={s['button-container']}>
          <button
            className={s['delete-button']}
            onClick={(e) => {
              e.preventDefault();
              if (onDeleteClick) {
                onDeleteClick();
              }
            }}
          >
            <Icon name="plus" color="#000000" size="sm" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Pill;
