import { createIsoDate } from '../utils';

const getMyEvents =
  (apiGet) =>
  (date = new Date()) =>
    apiGet(
      `/me/calendar/calendarView?startDateTime=${createIsoDate(
        new Date(date).setHours(0, 0, 0, 0)
      )}&endDateTime=${createIsoDate(
        new Date(date).setHours(23, 59, 59, 999)
      )}&$filter=isOrganizer eq true`
    );

export default getMyEvents;
