import { useState } from 'react';

import { useRoomAvailability } from '../../providers/roomAvailabilityProvider';

import InputBadge from '../../components/InputBadge/InputBadge';
import Office from '../../components/Office/Office';
import OfficeMap from '../../components/OfficeMap/OfficeMap';
import OngoingMeeting from '../../components/OngoingMeeting/OngoingMeeting';
import QuickBook from '../../components/QuickBook/QuickBook';
import Room from '../../components/Room/Room';

import s from './Home.module.scss';

const HomeMobile = ({ ongoingEvent, rooms }) => {
  const [isLocateRoomOpen, setIsLocateRoomOpen] = useState(false);

  const { activeRoomId, setActiveRoomId } = useRoomAvailability();

  const theMpyre = rooms.find(({ isTheEntireOffice }) => isTheEntireOffice);
  const meetingRooms = rooms.filter(({ isTheEntireOffice }) => !isTheEntireOffice);

  return (
    <div className={s.home} data-cy="home">
      {Boolean(ongoingEvent) && <OngoingMeeting event={ongoingEvent} />}

      <div className={s.viewToggle}>
        <InputBadge
          type="checkbox"
          id="ROOMS"
          text={isLocateRoomOpen ? 'Close Map' : 'Show Map'}
          name="mapToggle"
          checked={isLocateRoomOpen}
          size="large"
          handleClick={() => {
            setIsLocateRoomOpen(!isLocateRoomOpen);
            setActiveRoomId('');
          }}
        />
      </div>

      {isLocateRoomOpen && <OfficeMap />}

      <section className={s.section}>
        <div className={s.cardGrid} data-cy="rooms">
          {meetingRooms
            .sort((a, b) => a.size - b.size)
            .map((room) => (
              <Room key={room.id} room={room} isActive={room.id === activeRoomId} />
            ))}
          <div className={s.largeBookContainer}>
            <QuickBook />
          </div>
          {theMpyre && (
            <div className={s.largeBookContainer}>
              <Office room={theMpyre} />
            </div>
          )}
        </div>
        <div className={s.locateRoomDesktop}>
          <OfficeMap />
        </div>
      </section>
    </div>
  );
};

export default HomeMobile;
