import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const ChevronDown = ({ color, width = 24, height = 24 }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        d="M19.92 8.95001L13.4 15.47C12.63 16.24 11.37 16.24 10.6 15.47L4.07996 8.95001"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ChevronDown;
