import React, { useState } from 'react';

import { useMsal } from '@azure/msal-react';

import { loginRequest } from '../../authConfig';
import Button from '../Button/Button';

import './Login.scss';

const login = async (instance) => {
  try {
    await instance.loginRedirect(loginRequest);
  } catch (error) {
    console.error('error occured:', error);
  }
};

const Login = () => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    await login(instance);
    setLoading(false);
  };

  return (
    <div className="wrapper">
      <div className="login">
        <img
          // eslint-disable-next-line
          src={require(`../../assets/mpya-digital-logo.svg`)?.default}
          alt="book icon"
        />
        <Button
          type="button"
          color="transparent"
          onClick={handleLogin}
          data-cy="login-btn"
          loading={loading}
          fullWidth={true}
        >
          <span>Login</span>
        </Button>
      </div>
    </div>
  );
};

export default Login;
