import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import { Duration } from '../types/booking';
import { MeetingAttendee } from '../types/organization-user';
import { getDefaultDuration } from '../utils';
import roundDateToTimeSlot from '../utils/roundDateToTimeSlot/roundDateToTimeSlot';

interface BookingContextProps {
  timeslot: Date;
  duration: number;
  description: string;
  subject: string;
  attendees: MeetingAttendee[];
  isOverlap: boolean;
  setTimeslot: React.Dispatch<React.SetStateAction<Date>>;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  setSubject: React.Dispatch<React.SetStateAction<string>>;
  setAttendees: React.Dispatch<React.SetStateAction<MeetingAttendee[]>>;
  setIsOverlap: React.Dispatch<React.SetStateAction<boolean>>;
  clearBookingForm: () => void;
  defaultDuration: Duration;
  setDefaultDuration: React.Dispatch<React.SetStateAction<Duration>>;
}

const BookingContext = createContext<BookingContextProps | undefined>(undefined);

export const useBooking = (): BookingContextProps => {
  const context = useContext(BookingContext);

  if (!context) {
    throw new Error('Wrap consumer of useBooking in BookingProvider');
  }

  return context;
};

const BookingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [timeslot, setTimeslot] = useState(roundDateToTimeSlot(new Date(), true));
  const [duration, setDuration] = useState(15 * 60 * 1000);
  const [defaultDuration, setDefaultDuration] = useState(getDefaultDuration());
  const [description, setDescription] = useState('');
  const [subject, setSubject] = useState('');
  const [attendees, setAttendees] = useState<MeetingAttendee[]>([]);
  const [isOverlap, setIsOverlap] = useState(false);

  const clearBookingForm = useCallback(() => {
    setSubject('');
    setDescription('');
    setAttendees([]);
  }, []);

  return (
    <BookingContext.Provider
      value={{
        timeslot,
        duration,
        attendees,
        description,
        subject,
        isOverlap,
        setTimeslot,
        setDuration,
        setAttendees,
        setDescription,
        setSubject,
        setIsOverlap,
        clearBookingForm,
        defaultDuration,
        setDefaultDuration,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export { BookingProvider, BookingContext };
