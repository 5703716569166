import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const ChevronUp = ({ color, width = 24, height = 24 }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        d="M19.92 15.05L13.4 8.53001C12.63 7.76001 11.37 7.76001 10.6 8.53001L4.07996 15.05"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ChevronUp;
