import React from 'react';

import { ReactComponent as UserIcon } from '../../assets/user.svg';

import s from './PeopleSymbol.module.scss';

const PeopleSymbol = ({ people }) => {
  return (
    <div className={s.root}>
      <span className={s.people}>{people}</span>
      <UserIcon width="1rem" height="1rem" />
    </div>
  );
};

export default PeopleSymbol;
