import React, { ReactNode, useEffect, useRef } from 'react';
import { BottomSheetRef, BottomSheet as Sheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

import { useBooking } from '../../providers/bookingProvider';

import s from './BottomSheet.module.scss';

interface IBottomSheet {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  footer?: ReactNode;
}

const BottomSheet = ({ isOpen, setIsOpen, children, footer }: IBottomSheet) => {
  const bottomSheetRef = useRef<BottomSheetRef>(null);

  const { timeslot } = useBooking();

  const onTimeChange = () => {
    if (bottomSheetRef) {
      const target = document.querySelector('.scheduled-event');
      target?.scrollIntoView();
    }
  };

  useEffect(() => {
    if (timeslot) {
      onTimeChange();
    }
  }, [timeslot]);

  return (
    <Sheet
      ref={bottomSheetRef}
      open={isOpen}
      className={s.sheetStyle}
      snapPoints={({ minHeight, maxHeight }) => [
        // Since we want to clamp the height of the sheet when nothing is expanded
        // But keep a max size at 50%. We take the lowest value as the snap point.
        Math.min(minHeight, maxHeight - maxHeight / 2),
        minHeight,
      ]}
      onDismiss={() => setIsOpen(false)}
      defaultSnap={({ snapPoints, lastSnap }) => lastSnap ?? Math.min(...snapPoints)}
      footer={footer}
    >
      <div className={s['children-container']}>{children}</div>
    </Sheet>
  );
};

export default BottomSheet;
