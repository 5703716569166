/**
 *
 * @param date date to round
 * @param forceDown floors the value to always give an earlier time
 * @returns date rounded to nearest quarter
 */
const roundDateToTimeSlot = (date: Date, forceDown = false) => {
  // Calculate the number of minutes from the start of the day

  const minutes = date.getMinutes();

  // Round the number of minutes to the nearest 15-minute interval
  let roundedMinutes: number;

  if (forceDown) {
    roundedMinutes = Math.floor(minutes / 5) * 5;
  } else {
    roundedMinutes = Math.round(minutes / 15) * 15;
  }

  // Set the minutes of the date to the rounded value
  date.setMinutes(roundedMinutes);
  date.setSeconds(0);

  return date;
};

export default roundDateToTimeSlot;
