import { Link } from 'react-router-dom';

import { spaceOrEnterToClick } from '../../utils';
import Button from '../Button/Button';
import PeopleSymbol from '../PeopleSymbol/PeopleSymbol';
import classNames from 'classnames';

import s from './Office.module.scss';

const Office = ({ room }) => {
  return (
    <Link
      className={classNames(s.link)}
      to={`/rooms/${room.id}`}
      data-cy={`${room.id}`}
      onKeyDown={spaceOrEnterToClick}
    >
      <div className={s.header}>
        <h4 className={s.heading}>Book {room.name}</h4>
        <PeopleSymbol people={room.size} />
      </div>
      <div className={s.buttonsContainer}>
        <Button size="medium" color="yellow" dataCy="button-see-schedule">
          See Schedule
        </Button>
      </div>
    </Link>
  );
};

export default Office;
