import { useRef } from 'react';

import Button from '../Button/Button';

import s from './Modal.module.scss';

interface IModal {
  onClose: () => void;
  onDismiss?: () => void;
  closeBtnText?: string;
  title?: string;
  children: React.ReactNode;
  dataCyButton?: string;
}

export const Modal = ({
  children,
  closeBtnText,
  onClose,
  title,
  dataCyButton,
  onDismiss,
}: IModal) => {
  const modalBackground = useRef<HTMLDivElement>(null);

  const handleDismiss = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === modalBackground.current) {
      onDismiss && onDismiss();
    }
  };

  return (
    <div className={s.overlay} onClick={handleDismiss} ref={modalBackground}>
      <div className={s.modal}>
        {title && <h2>{title}</h2>}
        {children}
        {/* 
          INFO: since the button doesn't have a way to consume class names or have it's width modified via props
          we wrap it in a div. Otherwise the button takes up all the space that is available in a flex container
         */}
        <div className={s.button}>
          <Button size="medium" onClick={onClose} dataCy={dataCyButton}>
            {closeBtnText || 'close'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
