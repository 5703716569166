import { useEffect, useState } from 'react';

/**
 * Updates @value after @delay which causes consuming component to re-render.
 * Read more: https://usehooks-ts.com/react-hook/use-debounce
 * @param value - State, constant, or any value.
 * @param delay - Delay in milliseconds.
 * @returns copy of value
 */
export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
