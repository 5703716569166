import { useDate } from '../../providers/dateProvider';

interface IClock {
  timeOverride: Date;
  isOverlap: boolean;
}

const Clock: React.FC<IClock> = ({ timeOverride, isOverlap }) => {
  const { date } = useDate();

  return (
    <div className="clockContainer">
      <p>
        {date.getDay() !== new Date().getDay() &&
          ` ${date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })}`}
      </p>
      <h1 style={{ marginBottom: 0 }}>
        {timeOverride.toLocaleTimeString('sv', { timeStyle: 'short' })}
      </h1>
      <p style={{ marginTop: 0 }} className={`${isOverlap ? 'text-error' : 'text-success'}`}>
        {!isOverlap ? 'Available' : 'Not Available'}
      </p>
    </div>
  );
};

export default Clock;
