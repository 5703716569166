import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import useBreakpoint from '../../hooks/useBreakpoint';
import { useDate } from '../../providers/dateProvider';
import { useRoomAvailability } from '../../providers/roomAvailabilityProvider';
import { isSameDay, spaceOrEnterToClick } from '../../utils';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import InputRange from '../Input/InputRange';
import QuickBook from '../QuickBook/QuickBook';
import Settings from '../Settings/Settings';
import SignOut from './SignOut';

import s from './Header.module.scss';

export const Header = () => {
  const isHomePage = window.location.pathname === '/';

  const [isQuickBookOpen, setIsQuickbookOpen] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { date, incrementDay, decrementDay } = useDate();

  const { setActiveRoomId } = useRoomAvailability();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const breakpoint = useBreakpoint();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowProfileDropdown(false);
        setIsQuickbookOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <header className={s.header}>
      <div className={s.headerContent} ref={wrapperRef}>
        <Link
          className={s.logo}
          to="/"
          aria-label="Logo homepage"
          tabIndex={1}
          onKeyDown={spaceOrEnterToClick}
        >
          <img src={require('../../assets/logo.png')} alt="Rooms are joy logo" />
        </Link>

        <div className={s.dateContainer} data-cy="date-changer">
          {!isHomePage && (
            <>
              <InputRange
                value={date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })}
                iconType="arrows"
                onIncrement={incrementDay}
                onDecrement={decrementDay}
                isDecrementDisabled={isSameDay(date, new Date())}
                size="small"
                dataCy="date-changer-input"
                tabIndex={1}
              />
            </>
          )}
          {breakpoint !== 'mobile' && isHomePage && (
            <div className={s.quickBookButton}>
              <Button
                onClick={() => {
                  setIsQuickbookOpen((prev) => !prev);
                  setShowProfileDropdown(false);
                  setIsOpenModal(false);
                  setActiveRoomId(null);
                }}
                active={isQuickBookOpen}
                color={isQuickBookOpen ? 'yellow' : 'green'}
                tabIndex={1}
                className={s.headerButton}
              >
                <Icon name="quickBook" />
                Quick book
                <Icon size="sm" name={isQuickBookOpen ? 'chevronUp' : 'chevronDown'}></Icon>
              </Button>

              {isQuickBookOpen && (
                <div className={s.quickBookContainer}>
                  <QuickBook />
                </div>
              )}
            </div>
          )}
          <div className={s.profileButton}>
            <Button
              variant="iconButton"
              onClick={() => {
                setShowProfileDropdown((prev) => !prev);
                setIsQuickbookOpen(false);
                setActiveRoomId(null);
              }}
              active={showProfileDropdown}
              color="transparent"
              tabIndex={2}
              className={s.headerButton}
            >
              <Icon name="person" />
            </Button>

            {showProfileDropdown && (
              <div className={s.profileDropdown}>
                <ul>
                  <li>
                    <a onClick={() => setIsOpenModal((prev) => !prev)} href="#void">
                      <Icon name="settings" size="sm" /> Settings
                    </a>
                  </li>
                  <li>
                    <SignOut />
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <Settings isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </header>
  );
};
export default Header;
