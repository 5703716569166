import classNames from 'classnames';

import s from './Timeline.module.scss';

interface IInnerTimeline {
  translateValue: number;
  isAnimating: boolean;
  timelineRef: React.LegacyRef<HTMLDivElement>;
  children: React.ReactNode;
  dashed?: boolean;
}

const InnerTimeline = ({
  translateValue,
  timelineRef,
  isAnimating,
  children,
  dashed = false,
}: IInnerTimeline) => {
  return (
    <div className={classNames([s.timeline, s.container, dashed ? s.dashed : s.border])}>
      <div
        ref={timelineRef}
        className={classNames(s.timeline, s.wrapper, isAnimating ? s.animating : '')}
        style={{
          transform: `translateX(${translateValue}px)`,
        }}
      >
        {children}
        {[...Array(24)].map((_, _idx) => {
          return (
            <div key={_idx} className={classNames(s.inner, s.hourContainer)} onDrag={() => false}>
              <div className={s['inner-hourWrapper']}>
                {[...new Array(4)].map((_, idx) => (
                  <div key={idx} className={s.chunkContainer}>
                    {idx % 2 === 0 && (
                      <hr
                        dir="vertical"
                        className={classNames(s.timeIndicator, s.line)}
                        style={{
                          height: idx % 3 === 0 ? '15px' : '10px',
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InnerTimeline;
