import React, { ReactNode, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import useMsGraph from '../../hooks/useMsGraph';
import usePollForDeletion from '../../hooks/usePollForDeletion';
import { CalendarAttendee, CalendarMeeting } from '../../types/calendar-event';
import { parseTimeToString } from '../../utils';
import { ROOM } from '../../utils/constants/room-names';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Pill from '../Pill/Pill';

import s from './EventList.module.scss';

interface IEvent {
  data: CalendarMeeting;
  roomName: string;
  roomId: string;
  isOwner: boolean;
  isMobile: boolean;
  passed?: boolean;
  ongoing?: boolean;
}

const Event = ({
  data,
  isOwner,
  roomName,
  roomId,
  isMobile,
  passed = true,
  ongoing = false,
}: IEvent) => {
  const [expanded, setExpanded] = useState(false);
  const { deleteEvent } = useMsGraph();

  const { deleted, startPolling, stopPolling, isPolling } = usePollForDeletion({
    roomId,
    iCalUId: data.iCalUId,
  });

  const { mutate: deleteEventMutation, isLoading: mutationIsLoading } = useMutation(
    deleteEvent(data.id),
    {
      onSuccess: () => {
        startPolling(data.id);
      },
      onError: (_error) => {
        console.log(_error);
        stopPolling();
      },
    }
  );

  const formattedAttendees = useMemo(() => {
    // Filter out the organizer and the room from attendee list
    const deepCopy = [...data.attendees].filter(
      (attendee) =>
        attendee.emailAddress.name !== data.organizer.emailAddress.name &&
        !(roomName in ROOM && attendee.emailAddress.address === ROOM[roomName as keyof typeof ROOM])
    );

    return deepCopy.sort((attendee) => (attendee.status.response === 'accepted' ? -1 : 1));
  }, [data.attendees, data.organizer.emailAddress.name, roomName]);

  const renderPill = (attendees: React.ReactNode, next: CalendarAttendee, index: number) => {
    if (index < 2 && !expanded) {
      return [
        attendees,
        <Pill
          minWidth
          key={index}
          color={next.status.response === 'accepted' ? 'default' : 'disabled'}
        >
          {next.emailAddress.name}
        </Pill>,
      ];
    } else if (expanded) {
      return [
        attendees,
        <Pill
          minWidth
          key={index}
          color={next.status.response === 'accepted' ? 'default' : 'disabled'}
        >
          {next.emailAddress.name}
        </Pill>,
      ];
    } else {
      return attendees;
    }
  };

  if (deleted) return <></>;

  if (isMobile) {
    return (
      <div
        onClick={() => setExpanded((prev) => !prev)}
        className={`${s['event-container']} ${!expanded && passed ? s['passed'] : ''}`}
      >
        {passed || ongoing ? (
          <div className={`${s['event-indicator']} ${passed ? s['passed'] : s['ongoing']}`}></div>
        ) : (
          <div className={s['event-indicator']}></div>
        )}
        <div className={`${s['description-container']} ${passed && s['passed']}`}>
          <div className={`${s['description-grid']} ${expanded ? s['expanded'] : ''} `}>
            <h3>
              {`${parseTimeToString(new Date(data.start.dateTime))}-${parseTimeToString(
                new Date(data.end.dateTime)
              )}`}
            </h3>
            {!expanded && <p className={s['no-overflow']}>{data.subject}</p>}
            <div className={s['expand-icon']}>
              {isOwner ? (
                <Button
                  color="transparent"
                  variant="iconButton"
                  size="small"
                  onClick={() => deleteEventMutation()}
                  noShadow={true}
                >
                  {isPolling || mutationIsLoading ? (
                    <span className={`${s.spinner} ${s.medium}`} />
                  ) : (
                    <Icon name="trashBin" color="black" size="sm" />
                  )}
                </Button>
              ) : (
                data.bodyPreview !== '' && <Icon name={expanded ? 'chevronUp' : 'chevronDown'} />
              )}
            </div>
          </div>

          {expanded && (
            <>
              <h4>{data.subject}</h4>
              <div className={s['pill-container']}>
                <Pill color="highlight">{data.organizer.emailAddress.name}</Pill>
                {formattedAttendees.reduce(
                  (attendees, next, index) => renderPill(attendees, next, index),
                  [] as ReactNode
                )}

                {data.bodyPreview !== '' && (
                  <div className={s['body-container']}>
                    {/* TODO: probably remove and make a bit more fun */}
                    <p> {data.bodyPreview.split(/.+?(_)/)[0]}</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div onClick={() => setExpanded((prev) => !prev)} className={s['event-container']}>
        {(passed || ongoing) && (
          <div className={`${s['event-indicator']} ${passed ? s['passed'] : s['ongoing']}`}></div>
        )}
        <div className={`${s['description-container']} ${passed && s['passed']}`}>
          <div className={`${s['description-grid']} `}>
            {
              <h3 className={`${s['no-overflow']} ${expanded ? s['expanded'] : ''}`}>
                {data.subject}
              </h3>
            }
            <div className={s['expand-icon']}>
              {isOwner ? (
                <Button
                  color="transparent"
                  variant="iconButton"
                  size="small"
                  noShadow={true}
                  onClick={() => deleteEventMutation()}
                >
                  {isPolling || mutationIsLoading ? (
                    <span className={`${s.spinner} ${s.medium}`} />
                  ) : (
                    <Icon name="trashBin" color="black" size="sm" />
                  )}
                </Button>
              ) : (
                data.bodyPreview !== '' && <Icon name={expanded ? 'chevronUp' : 'chevronDown'} />
              )}
            </div>
          </div>
          <p>
            {`${parseTimeToString(new Date(data.start.dateTime))}-${parseTimeToString(
              new Date(data.end.dateTime)
            )}`}
          </p>

          <div className={s['pill-container']}>
            <Pill color="highlight">{data.organizer.emailAddress.name}</Pill>
            {formattedAttendees.reduce(
              (attendees, next, index) => renderPill(attendees, next, index),
              [] as ReactNode
            )}

            {!expanded && formattedAttendees.length > 2 && (
              <Pill color="default"> {`+${formattedAttendees.length - 2}`}</Pill>
            )}

            {expanded && data.bodyPreview !== '' && (
              <div className={s['body-container']}>
                {/* TODO: probably remove and make a bit more fun */}
                <p> {data.bodyPreview.split(/.+?(_)/)[0]}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Event;
