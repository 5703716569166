import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const ClockSolidDuo = ({ color, outlineColor, width, height }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
        fill={outlineColor}
      />
      <path
        d="M15.71 15.932C15.5752 15.9344 15.4427 15.8961 15.33 15.822L12.23 13.972C11.46 13.512 10.89 12.502 10.89 11.612V7.51202C10.89 7.10202 11.23 6.76202 11.64 6.76202C12.05 6.76202 12.39 7.10202 12.39 7.51202V11.612C12.39 11.972 12.69 12.502 13 12.682L16.1 14.532C16.46 14.742 16.57 15.202 16.36 15.562C16.292 15.6739 16.1967 15.7665 16.083 15.8312C15.9692 15.896 15.8409 15.9307 15.71 15.932V15.932Z"
        fill={color}
      />
    </Svg>
  );
};

export default ClockSolidDuo;
