import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const Remove = ({ color, width, height }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        d="M7.75741 16.2426L16.2427 7.75736M16.2427 16.2426L7.75741 7.75736"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Remove;
