import { MS_SCHEDULE_AVAILABILITY_VIEW_INTERVAL } from '../config';
import useMsGraphCall from './useMsGraphCall';
import { ScheduleInformation } from '@microsoft/microsoft-graph-types';

const useGetSchedule = (email: string[], roomId: string, date = new Date()) => {
  const { apiPost } = useMsGraphCall();

  const startOfDay = new Date(new Date(date).setHours(0, 0, 0, 0));
  const endOfDay = new Date(new Date(date).setHours(23, 59, 59, 999));
  const scheduleInformation = {
    schedules: email,
    startTime: {
      dateTime: startOfDay,
      timeZone: 'UTC',
    },
    endTime: {
      dateTime: endOfDay,
      timeZone: 'UTC',
    },
    availabilityViewInterval: MS_SCHEDULE_AVAILABILITY_VIEW_INTERVAL,
  };

  return apiPost<{ value: ScheduleInformation[] }, typeof scheduleInformation>(
    `/users/${roomId}/calendar/getSchedule`,
    scheduleInformation
  );
};

export default useGetSchedule;
