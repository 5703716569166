export const msalConfig = {
  auth: {
    clientId: 'fa828528-14f9-4dd7-9a10-16c188529969',
    authority: 'https://login.microsoftonline.com/aea5ed0a-d315-4fb1-b4f3-9137000c093b',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['Calendars.ReadWrite', 'Calendars.ReadWrite.Shared', 'User.ReadBasic.All'],
};

export const ssoLoginSilent = async (msalInstance) => {
  try {
    await msalInstance.ssoSilent(loginRequest);
  } catch (e) {
    // Do nothing
    // ssoSilent might return an Interaction required if the user is signed in to multiple accounts.
    // For this app, we handle this case by letting the user sign in manually and then select account.
  }
};
