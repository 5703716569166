import minutesTo from '../minutesTo/minutesTo';

const createIsoDate = (date: number) => {
  const timeZoneOffset = new Date().getTimezoneOffset() * minutesTo.milliseconds;
  const localISOTime = new Date(date - timeZoneOffset).toISOString();

  return localISOTime;
};

export default createIsoDate;
