import { useRef, useState } from 'react';

import Tooltip from '../Tooltip/Tooltip';
import classNames from 'classnames';

import s from './OfficeMap.module.scss';

interface IPin {
  isActive: boolean;
  isAvailable: boolean;
  roomName: string;
  onClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
}

const Pin = ({ isActive, roomName, isAvailable, onClick }: IPin) => {
  const [isOpen, setIsOpen] = useState(false);
  const targetRef = useRef<HTMLDivElement>(null);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (['Enter', ' ', 'Space'].includes(e.key)) {
      e.preventDefault();
      onClick(e);
    }
  };

  return (
    <div className={s.pin} onClick={onClick} tabIndex={0} onKeyDown={onKeyDown}>
      <div
        onMouseOver={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        ref={targetRef}
        className={classNames(s.outerCircle, isActive && s.active, isAvailable && s.available)}
      >
        <div className={classNames(s.innerCircle, isAvailable && s.available)} />
      </div>
      <div className={s.stick} />
      <Tooltip isVisible={isOpen} targetRef={targetRef}>
        {roomName}
      </Tooltip>
    </div>
  );
};

export default Pin;
