import { useMsal } from '@azure/msal-react';

import useBreakpoint from '../../hooks/useBreakpoint';
import { useBooking } from '../../providers/bookingProvider';
import { CalendarMeeting } from '../../types/calendar-event';
import { createCETDate, minutesTo } from '../../utils';
import Event from './Event';
import PlannedEvent from './PlannedEvent';

import s from './EventList.module.scss';

interface IEventList {
  events: CalendarMeeting[];
  roomId: string;
  roomName: string;
}

const EventList = ({ events, roomName, roomId }: IEventList) => {
  const { accounts } = useMsal();
  const { duration, subject, description, isOverlap, timeslot, attendees } = useBooking();
  const breakpoint = useBreakpoint();

  const renderPlannedMeeting = () => {
    const UTCtime = new Date(timeslot);
    UTCtime.setHours(timeslot.getUTCHours());
    UTCtime.setMinutes(timeslot.getUTCMinutes());
    UTCtime.setSeconds(timeslot.getUTCSeconds());

    return (
      <PlannedEvent
        key={UTCtime.getTime()}
        attendees={attendees}
        subject={subject}
        description={description}
        organizer={accounts[0].name ?? ''}
        startTime={UTCtime}
        endTime={new Date(UTCtime.getTime() + duration)}
        isMobile={breakpoint === 'mobile'}
        isOverlap={isOverlap}
      />
    );
  };

  const renderMeeting = (
    key: string,
    data: CalendarMeeting,
    roomName: string,
    isOwner: boolean,
    passed: boolean,
    ongoing: boolean
  ) => {
    return (
      <Event
        key={key}
        data={data}
        roomName={roomName}
        roomId={roomId}
        isOwner={isOwner}
        passed={passed}
        ongoing={ongoing}
        isMobile={breakpoint === 'mobile'}
      />
    );
  };

  const generateMeetingList = (events: CalendarMeeting[]) => {
    if (!events) return;

    let plannedRendered = false;

    return events.map((event, idx) => {
      const startTime = createCETDate(event.start.dateTime);
      const endTime = createCETDate(event.end.dateTime);
      const now = new Date();

      const isPassed = now > endTime;
      const isOngoing = startTime <= endTime && startTime <= now;

      if (timeslot > endTime && !plannedRendered) {
        // Check if next index is defined and if the planned meeting occurs before next (minus one minute because equal doesn't work.)
        const isValidIndex = idx < events.length && events[idx + 1];
        const isCurrLessOrEqual = endTime <= new Date(timeslot.getTime() + duration);
        const isNextLess = isValidIndex
          ? createCETDate(events[idx + 1].start.dateTime) >
            new Date(timeslot.getTime() + duration - 1 * minutesTo.milliseconds)
          : false;
        const isEventLater = idx === events.length - 1 && endTime < timeslot;

        if ((isCurrLessOrEqual && isNextLess) || isEventLater) {
          plannedRendered = true;

          return [
            renderMeeting(event.id, event, roomName, event.isOrganizer, isPassed, isOngoing),
            renderPlannedMeeting(),
          ];
        }
      } else if (timeslot < endTime && !plannedRendered) {
        plannedRendered = true;

        return [
          renderPlannedMeeting(),
          renderMeeting(event.id, event, roomName, event.isOrganizer, isPassed, isOngoing),
        ];
      }

      return renderMeeting(event.id, event, roomName, event.isOrganizer, isPassed, isOngoing);
    });
  };

  // If fetching and not events, always render the currently booking meeting
  if (!events || !events?.length) {
    return renderPlannedMeeting();
  }

  return <div className={s['event-list']}>{generateMeetingList(events as CalendarMeeting[])}</div>;
};

export default EventList;
