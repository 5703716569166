import s from './StatusMessage.module.scss';

type TVariant = 'green' | 'red';

interface IStatusMessage {
  children?: React.ReactNode;
  variant: TVariant;
  dataCy?: string;
}

const StatusMessage = ({ children, variant, dataCy }: IStatusMessage) => (
  <h4 className={s[variant]} data-cy={dataCy}>
    {children}
  </h4>
);

export default StatusMessage;
