import { useMsal } from '@azure/msal-react';

import Icon from '../Icon/Icon';

const signOut = (instance) => () => {
  sessionStorage.clear();
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
};

const SignOut = () => {
  const { instance } = useMsal();

  return (
    <a onClick={signOut(instance)} href="#void">
      <Icon name="signOut" size="sm"></Icon> Sign out
    </a>
  );
};

export default SignOut;
