import classNames from 'classnames';

import s from './InputBadge.module.scss';

type TSize = 'small' | 'large';
type Ttype = 'radio' | 'checkbox';

interface IInputBadge {
  id: string;
  text: string;
  name: string;
  checked: boolean;
  type: Ttype;
  size?: TSize;
  handleClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputBadge = ({
  id,
  text,
  name,
  checked,
  type,
  size = 'small',
  handleClick,
}: IInputBadge) => (
  <label htmlFor={id} className={s.label}>
    <input
      className={classNames(s.inputBadge, s[size])}
      type={type}
      id={id}
      name={name}
      checked={checked}
      onChange={handleClick}
    />
    <span data-text={text}>{text}</span>
  </label>
);
export default InputBadge;
