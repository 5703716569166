import React, { useLayoutEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { useDebounce } from '../../hooks/useDebounce';
import useMsGraph from '../../hooks/useMsGraph';
import { useBooking } from '../../providers/bookingProvider';
import { MeetingAttendee, OrganizationUser } from '../../types/organization-user';
import OptionDropdown from '../OptionDropdown/OptionDropdown';
import PillInput from '../PillInput/PillInput';

import s from './BookingForm.module.scss';

const InviteAttendees = () => {
  const [query, setQuery] = useState('');
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [hoveredTarget, setHoveredTarget] = useState(0);

  const { attendees, setAttendees } = useBooking();
  const { getOrgUsers } = useMsGraph();
  const bouncedQuery = useDebounce(query, 500);

  const { data: users, isLoading } = useQuery(
    ['getOrgUsers', bouncedQuery],
    getOrgUsers(bouncedQuery),
    {
      enabled: bouncedQuery.length >= 2,
    }
  );

  const inputRef = useRef<HTMLDivElement>(null);
  const inputFieldRef = useRef<HTMLInputElement>(null);

  const addAttendee = (user: OrganizationUser) => {
    const selectedTarget = {
      displayName: user.displayName,
      mail: user.mail,
    };

    setAttendees((prev) => {
      const isAlreadyInvited = prev.some(
        (attendee: MeetingAttendee) => attendee.displayName === selectedTarget.displayName
      );

      if (isAlreadyInvited) {
        return prev.filter((attendee) => attendee.displayName !== selectedTarget.displayName);
      } else {
        return [...prev, selectedTarget];
      }
    });
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
      setIsInputFocus(false);
    } else if (inputFieldRef.current) {
      inputFieldRef.current.focus();
    }
  };

  const onPillDelete = (idx: number) => {
    setAttendees((prev) => prev.filter((_att, i) => idx !== i));
  };

  const onInputKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        if (!users) return;

        // Reset input when adding new value
        setQuery('');
        setIsInputFocus(false);
        setHoveredTarget(0);

        // Before adding attendee, we need to filter the users result since we don't modify the original response.
        const filteredList = users.value.filter(
          (usr) => !attendees.some((attendee) => attendee.displayName === usr.displayName)
        );

        addAttendee(filteredList[hoveredTarget]);
        break;
      case 'Backspace':
        // remove last element in attendees array if there is no query
        if (!query.length) {
          setAttendees((prev) => prev.slice(0, -1));
        }

        break;
      case 'ArrowDown':
        setHoveredTarget((prev) => {
          if (users && prev < users.value.length) {
            return prev + 1;
          }

          return prev;
        });
        break;
      case 'ArrowUp':
        setHoveredTarget((prev) => {
          if (prev > 0) {
            return prev - 1;
          }

          return prev;
        });
        break;
      case 'Escape':
        setIsInputFocus(false);
        break;
      default:
        // If a new input is done, make sure that the input state is flipped to true
        setIsInputFocus(true);
        break;
    }
  };

  const getDropdownList = () => {
    if (!users) return [];

    return users.value.reduce((usr, next) => {
      if (attendees.some((attendee) => attendee.displayName === next.displayName)) {
        return usr;
      }

      return [...usr, next.displayName];
    }, [] as string[]);
  };

  // Add event listeners on document to detect clicks. If the target is outside the input div,
  // it will be hidden.
  useLayoutEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }} ref={inputRef}>
      <PillInput
        inputRef={inputFieldRef}
        value={query}
        isFocus={isInputFocus && users && users.value.length > 0 ? true : false}
        pillData={attendees.map((attendee) => attendee.displayName)}
        onPillDelete={onPillDelete}
        onChange={setQuery}
        onFocus={setIsInputFocus}
        onKeyDown={onInputKeyDown}
      />
      {isInputFocus &&
        users &&
        users.value.length > 0 &&
        (isLoading ? (
          <span className={s.spinner} />
        ) : (
          <OptionDropdown
            data={getDropdownList()}
            optionLimit={3}
            hoveredTarget={hoveredTarget}
            isLoading={isLoading}
            setHoveredTarget={(idx: number) => setHoveredTarget(idx)}
            addChosen={(item: string) => {
              const [newAttendee] = users.value.filter((usr) => usr.displayName === item);

              if (newAttendee) {
                addAttendee(newAttendee);

                setQuery('');
                setIsInputFocus(false);
              }
            }}
          />
        ))}
    </div>
  );
};

export default InviteAttendees;
