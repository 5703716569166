import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const Minus = ({ color, width = 24, height = 24 }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        d="M6 12H18"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Minus;
