/**
 * Fixture that has no bookings
 */
export const bookedNever = {
  '@odata.context':
    'https://graph.microsoft.com/v1.0/$metadata#Collection(microsoft.graph.scheduleInformation)',
  value: [
    {
      scheduleId: 'tomcrews@mpya.se',
      availabilityView:
        '000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000',
      scheduleItems: [],
      workingHours: {
        daysOfWeek: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
        startTime: '08:00:00.0000000',
        endTime: '17:00:00.0000000',
        timeZone: { name: 'Pacific Standard Time' },
      },
    },
  ],
};
