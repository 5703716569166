import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import useMsGraph from '../../hooks/useMsGraph';
import usePollEventForAcceptance from '../../hooks/usePollEventForAcceptance';
import { useBooking } from '../../providers/bookingProvider';
import { useDate } from '../../providers/dateProvider';
import Button from '../Button/Button';

const BookButton = ({
  userDisplayName,
  roomEmail,
  roomName,
  roomId,
  attendees = [],
  timeslot,
  duration,
  description = '',
  subject = '',
  size,
  isRefetching,
  dataCy,
  children,
  isDisabled = false,
  fullWidth = false,
}) => {
  const { createEvent } = useMsGraph();
  const { startPolling, accepted, isPolling } = usePollEventForAcceptance({
    roomEmail,
    roomId,
  });

  const [showSuccess, setSuccess] = useState(false);

  const [eventId, setEventId] = useState('');
  const { date } = useDate();

  const { deleteEvent } = useMsGraph();
  const queryClient = useQueryClient();

  const { clearBookingForm } = useBooking();

  // INFO: This destructuring is a workaround to use the mutate function as a dependency in useEffect
  // the useMutation result object cannot be used as a dependency because it will have a new reference on each render
  const { mutate: deleteEventMutation } = useMutation((id) => deleteEvent(id)(), {
    onSuccess: () => {
      setEventId('');
      queryClient.invalidateQueries('getMyEvents');
      queryClient.invalidateQueries('getRoomEvents');
    },
  });

  const createEventMutation = useMutation((body) => createEvent(body)(), {
    onSuccess: (data) => {
      startPolling(data.id);
      setEventId(data.id);
    },
    onError: () => {
      setEventId('');
      alert('Booking failed');
    },
  });

  useEffect(() => {
    if (accepted === false && eventId) {
      window.alert(
        'Since you obviously tried to commit a henious act and steal someone elses meeting time I took the liberty to help you become a better human being and denied your request😎'
      );
      deleteEventMutation(eventId);
    }
  }, [accepted, eventId, deleteEventMutation]);

  useEffect(() => {
    if (accepted) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
      clearBookingForm();
    }
  }, [accepted, eventId, clearBookingForm]);

  const TIMEZONE = 'Europe/Stockholm';

  const book = (e) => {
    const currentDateTime = new Date(date || timeslot);
    const meetingTimeslot = new Date(timeslot);

    currentDateTime.setHours(meetingTimeslot.getHours(), meetingTimeslot.getMinutes(), 0, 0);

    e.preventDefault();

    const event = {
      subject: subject || userDisplayName,
      body: Boolean(description)
        ? {
            contentType: 'html',
            content: `<div id="description">${description}</div>`,
          }
        : undefined,
      start: {
        dateTime: currentDateTime.toISOString(),
        timeZone: TIMEZONE,
      },
      end: {
        dateTime: new Date(currentDateTime.getTime() + duration),
        timeZone: TIMEZONE,
      },
      location: {
        displayName: roomName,
        locationEmailAddress: roomEmail,
      },
      attendees: [
        {
          emailAddress: {
            address: roomEmail,
            name: roomName,
          },
          type: 'resource',
        },
        ...attendees.map((attendee) => ({
          emailAddress: {
            address: attendee.mail,
            name: attendee.displayName,
          },
          type: 'required',
        })),
      ],
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness',
    };
    createEventMutation.mutate(event);
  };

  const isLoading = isRefetching || createEventMutation.isLoading || isPolling;

  return (
    <Button
      onClick={book}
      color="green"
      loading={isLoading}
      size={size}
      fullWidth={fullWidth}
      dataCy={dataCy}
      disabled={isDisabled}
      success={showSuccess}
    >
      {children}
    </Button>
  );
};

export default BookButton;
