import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import useBreakpoint from '../../hooks/useBreakpoint';
import useMsGraph from '../../hooks/useMsGraph';
import { secondsTo, timeNowInUTC } from '../../utils';
import HomeDesktop from './Home.desktop';
import HomeMobile from './Home.mobile';

const Home = ({ rooms }) => {
  const [ongoingEvent, setOngoingEvent] = useState();

  const breakpoint = useBreakpoint();
  const { getMyEvents } = useMsGraph();

  const { data: myEventsToday } = useQuery(['getMyEvents'], getMyEvents(), {
    refetchInterval: 60 * secondsTo.milliseconds,
    refetchIntervalInBackground: true,
  });

  const upcommingEvents = myEventsToday?.value?.filter(
    (item) => new Date(item.end.dateTime) >= timeNowInUTC()
  );

  useEffect(() => {
    const getTime = (timeString) => new Date(timeString);
    const now = timeNowInUTC();
    let ongoingEvent;
    if (upcommingEvents?.length > 0) {
      ongoingEvent = upcommingEvents.find(
        ({ start, end, location }) =>
          getTime(start.dateTime) < now &&
          getTime(end.dateTime) > now &&
          rooms.map(({ name }) => name).includes(location.displayName)
      );
    }

    setOngoingEvent(ongoingEvent);
  }, [rooms, myEventsToday?.value, upcommingEvents]);

  return breakpoint === 'mobile' ? (
    <HomeMobile ongoingEvent={ongoingEvent} rooms={rooms} />
  ) : (
    <HomeDesktop ongoingEvent={ongoingEvent} />
  );
};

export default Home;
