import { useCallback, useEffect, useRef, useState } from 'react';

import { CalendarMeeting } from '../../types/calendar-event';
import { minutesTo, parseTimeToString } from '../../utils';
import Tooltip from '../Tooltip/Tooltip';
import classNames from 'classnames';

import s from './Timeline.module.scss';

interface IMeetingProps {
  barWidth: number;
  time: Date;
  length: number;
  oneHourScale: number;
  passedInTime?: boolean;
  disabled?: boolean;
  overlap?: boolean;
  maxTranslate?: number;
  schedule?: boolean;
  meetingData?: CalendarMeeting;
}

const Meeting = ({
  meetingData,
  barWidth,
  time,
  length,
  oneHourScale,
  passedInTime = false,
  overlap,
  schedule = false,
  disabled = false,
}: IMeetingProps) => {
  const [scheduledX, setScheduledX] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  let id = `${meetingData?.subject ?? ''} ${meetingData?.start.dateTime ?? ''}`;

  const targetRef = useRef<HTMLDivElement>(null);

  const getOffsetFromTime = useCallback(
    (time: Date) => {
      const clientWidth = barWidth;
      const now = time;

      // Calculate the number of milliseconds that have passed today
      const timeToday =
        now.getTime() - new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();

      // Calculate the total number of milliseconds in a day
      const msInDay = 24 * 60 * minutesTo.milliseconds;

      // Calculate the percentage of time that has passed today
      const percentToday = timeToday / msInDay;

      setScheduledX(percentToday * clientWidth);
    },
    [barWidth]
  );

  const getLength = () => {
    return `${oneHourScale * length - 3}px`;
  };

  // In order for the time slot to appear in the correct spot, as well as snapping on the grid
  // Ned to make suure that the container is going up to gridsize.
  const getMargin = () => {
    return `${length * oneHourScale}px`;
  };

  const getClasses = () => {
    if (passedInTime) return s.passed;

    if (disabled) return s.disabled;

    if (overlap) return s.overlap;
  };

  useEffect(() => {
    getOffsetFromTime(time);
  }, [time, getOffsetFromTime]);

  return scheduledX ? (
    <>
      <div
        ref={targetRef}
        id={id}
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className={classNames(s.scheduleEvent, getClasses())}
        style={{
          transform: `translateX(${disabled ? scheduledX : 0}px)`,
          width: getLength(),
          marginLeft: disabled ? 0 : getMargin(),
          zIndex: !schedule ? 1 : 1,
        }}
      >
        {disabled && <p className={s.tooltipText}>{meetingData?.subject}</p>}

        {targetRef.current && disabled && (
          <Tooltip isVisible={showTooltip} targetRef={targetRef}>
            {parseTimeToString(new Date(meetingData?.start.dateTime ?? ''))}
            {'-'}
            {parseTimeToString(new Date(meetingData?.end.dateTime ?? ''))}: {meetingData?.subject}
          </Tooltip>
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

export default Meeting;
