import { bookedInTheFuture } from './bookedInTheFuture';
import { bookedNever } from './bookedNever';
import { bookedNow } from './bookedNow';

/**
 * Serve edge case fixtures for decided rooms. The key is the id of the room (see: /src/rooms.json)
 */
const roomIdToFixedSchedule = {
  // Crew BB
  '09b096b3-70ab-405d-ac4a-d7b738c685db': bookedInTheFuture,
  // Penelope Crews
  'ae21a914-c12c-41cf-bbad-adf3ebfbc5a8': bookedNow,
};

export const fixedScheduleToRoomId = {
  bookedInTheFuture: ['09b096b3-70ab-405d-ac4a-d7b738c685db'],
  bookedNow: ['ae21a914-c12c-41cf-bbad-adf3ebfbc5a8'],
  bookedNever: [
    '8b7c02c1-01f5-4fcb-98ea-f93e28030f18',
    '5ab35a13-f298-46d7-b2da-9772c54193e3',
    'a6bf7e72-3f3e-46bb-bca4-3d5bb59f65ac',
    '8a9949bd-c8f2-4bde-9a9e-94e824a2e147',
  ],
};

export const getSchedule = (id) => roomIdToFixedSchedule[id] || bookedNever;
