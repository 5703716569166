import { MS_GRAPH_BASE_URL } from '../config';
import { getSchedule, meEvents, usersEvents } from './fixtures/msGraph';
import { rest } from 'msw';

export const handlers = [
  rest.post(`${MS_GRAPH_BASE_URL}/users/:userId/calendar/getSchedule`, (req, res, ctx) => {
    return res(ctx.json(getSchedule(req.params.userId)));
  }),

  rest.get(`${MS_GRAPH_BASE_URL}/me/calendar/calendarView`, (req, res, ctx) => {
    return res(ctx.json(meEvents));
  }),

  rest.get(`${MS_GRAPH_BASE_URL}/users/:userId/calendarView`, (req, res, ctx) => {
    // INFO: Serve same response regardless of :userID (:userId represents the room)
    // TODO: Serve custom fixture for each :userId
    return res(ctx.json(usersEvents));
  }),
];
