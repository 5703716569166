import { useRoomAvailability } from '../../providers/roomAvailabilityProvider';

import OfficeMap from '../../components/OfficeMap/OfficeMap';
import Schedule from '../../components/Schedule/Schedule';
import Sidebar from '../../components/Sidebar/Sidebar';

import s from './Home.module.scss';

const HomeDesktop = ({ ongoingEvent }) => {
  const { activeRoomId, setActiveRoomId } = useRoomAvailability();

  return (
    <div data-cy="home">
      <section className={s.section}>
        <div className={s.cardGrid} data-cy="rooms">
          <Sidebar activeRoomId={activeRoomId} ongoingEvent={ongoingEvent} />
        </div>
        <div className={s.mapWrapper}>
          <div>
            <OfficeMap
              activeRoomId={activeRoomId}
              style={{ gridRow: '1 / 2' }}
              setActiveRoomId={setActiveRoomId}
            />
          </div>

          <Schedule />
        </div>
      </section>
    </div>
  );
};

export default HomeDesktop;
