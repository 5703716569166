import React, { ReactNode } from 'react';

interface ISvg {
  width: number;
  height: number;
  fill?: string;
  viewBox?: string;
  children: ReactNode;
}

const Svg = ({ width, height, viewBox, children, fill = 'none' }: ISvg) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={viewBox ? viewBox : `0 0 24 24`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

export default Svg;
