import React from 'react';

import Pill from '../Pill/Pill';

import s from './PillInput.module.scss';

interface IPillInput {
  value: string;
  pillData: string[];
  isFocus: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  onFocus: React.Dispatch<React.SetStateAction<boolean>>;
  onKeyDown: (event: React.KeyboardEvent) => void;
  onPillDelete: (idx: number) => void;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

const PillInput = ({
  value,
  pillData,
  isFocus,
  onChange,
  onFocus,
  onKeyDown,
  onPillDelete,
  inputRef,
}: IPillInput) => {
  return (
    <div className={`${s.container} ${isFocus ? `${s.focused}` : ''}`}>
      {pillData.map((attendee, idx) => (
        <Pill key={idx} deletable onDeleteClick={() => onPillDelete(idx)}>
          {attendee}
        </Pill>
      ))}

      <input
        ref={inputRef}
        className={s['pill-input']}
        type={'text'}
        value={value}
        placeholder={'Invite Attendee'}
        onFocus={() => {
          onFocus(true);
        }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default PillInput;
