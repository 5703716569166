import { useState } from 'react';

import profileIconPath from '../../assets/profile.svg';
import { useBooking } from '../../providers/bookingProvider';

import BookButton from '../../components/BookingForm/BookButton';
import BookingForm from '../../components/BookingForm/BookingForm';
import BottomSheet from '../../components/BottomSheet/BottomSheet';
import Button from '../../components/Button/Button';
import EventList from '../../components/EventList/EventList';
import Icon from '../../components/Icon/Icon';
import MeetingSchedule from '../../components/MeetingSchedule/MeetingSchedule';

import s from './RoomDetails.module.scss';

const RoomDetailsMobile = ({ accounts, events, isRefetching, room }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { isOverlap } = useBooking();

  const { email, name, id, size } = room;

  return (
    <>
      {events && (
        <div className={s.roomDetails}>
          <div className={s.roomHeader}>
            <div className={s.roomNameContainer}>
              <h1 className={s.roomNameHeading} data-text={name.toUpperCase()}>
                {name.toUpperCase()}
              </h1>
            </div>
            <div className={s.roomSizeContainer}>
              <h1>{size}</h1>
              <img src={profileIconPath} alt="Left button" />
            </div>
          </div>

          <MeetingSchedule events={events} />
          <div className={s['scheduleButton']}>
            <Button size="small" color="primary" onClick={() => setIsDrawerOpen((prev) => !prev)}>
              {isDrawerOpen ? 'Hide all bookings' : 'See all bookings'}
            </Button>
          </div>
          <BottomSheet
            isOpen={isDrawerOpen}
            setIsOpen={setIsDrawerOpen}
            footer={
              <BookButton
                size="large"
                roomEmail={email}
                roomName={name}
                roomId={id}
                fullWidth
                userDisplayName={accounts[0].username}
                isRefetching={isRefetching}
                isDisabled={isOverlap}
              >
                <span>Book</span> <Icon name="people" color="black" size="sm" />
              </BookButton>
            }
          >
            {isRefetching ? (
              <span className={s.spinner}></span>
            ) : (
              <EventList events={events} roomId={id} roomName={name} />
            )}
          </BottomSheet>

          <BookingForm roomId={id} roomEmail={email} roomName={name} isRefetching={isRefetching} />
        </div>
      )}
    </>
  );
};

export default RoomDetailsMobile;
