import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';

import useBreakpoint from '../../hooks/useBreakpoint';
import useMsGraph from '../../hooks/useMsGraph';
import { useDate } from '../../providers/dateProvider';
import { compareEmailAddresses } from '../../utils';
import RoomDetailsDesktop from './RoomDetails.desktop';
import RoomDetailsMobile from './RoomDetails.mobile';

const RoomDetails = ({ rooms }) => {
  const [events, setEvents] = useState();

  const { getRoomEvents, getMyEvents } = useMsGraph();
  const { roomId } = useParams();
  const { date } = useDate();
  const { accounts } = useMsal();
  const breakpoint = useBreakpoint();

  const room = rooms.find((room) => room.id === roomId);

  const { data: calendarEventsToday, isFetching: isRefetchingCalendarEventsToday } = useQuery(
    ['getRoomEvents', room.id, date],
    getRoomEvents(room.id, date)
  );

  const { data: myEventsToday, isFetching: isRefetchingMyEventsToday } = useQuery(
    ['getMyEvents', room.id, date],
    getMyEvents(date)
  );

  useEffect(() => {
    // Merge myEventsToday with calendarEventsToday to avoid duplicates
    if (myEventsToday?.value && calendarEventsToday?.value) {
      const combinedEvents = [
        ...calendarEventsToday.value.filter(
          (event) => !myEventsToday.value.map((i) => i.iCalUId).includes(event.iCalUId)
        ),
        ...myEventsToday.value,
      ];

      // Filter out events that do not have the room in list of attendees
      const events = combinedEvents.filter(
        ({ attendees, location }) =>
          attendees.find(
            ({ emailAddress, status }) =>
              emailAddress.address &&
              compareEmailAddresses(emailAddress.address, room.email) &&
              ['accepted', 'notResponded'].includes(status.response)
          ) ||
          // Outlook has a bug such that the room might not always be in the list of attendees
          // if added through outlook so we look if the room is the location as fallback
          (location.locationUri?.includes('@') &&
            compareEmailAddresses(location.locationUri, room.email))
      );

      // Sort by start.dateTime, otherwise it is sorted by "lastModifiedDateTime"
      const sortedEvents = events.sort((a, b) => {
        if (new Date(b.start.dateTime) > new Date(a.start.dateTime)) {
          return -1;
        }

        return 1;
      });
      setEvents(sortedEvents);
    }
  }, [myEventsToday, calendarEventsToday, room.email]);

  const isRefetching = isRefetchingCalendarEventsToday || isRefetchingMyEventsToday;

  return breakpoint === 'mobile' ? (
    <RoomDetailsMobile
      events={events}
      accounts={accounts}
      isRefetching={isRefetching}
      room={room}
    />
  ) : (
    <RoomDetailsDesktop
      events={events}
      accounts={accounts}
      isRefetching={isRefetching}
      room={room}
    />
  );
};

export default RoomDetails;
