import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import App from './App';
import { msalConfig } from './authConfig';

import './index.scss';

export const msalInstance = new PublicClientApplication(msalConfig);

// INFO: add PublicClientApplication to window to spy on and stub in e2e tests
// Only do it if Cypress is running
if (window.Cypress) {
  window.msalInstance = msalInstance;
  const { worker } = require('./mocks/browser');
  window.msw = { worker };
}

if (process.env.REACT_APP_WITH_MOCKS) {
  const { worker } = require('./mocks/browser');
  worker.start();

  const { mockGetAllAccounts } = require('./mocks/functions/mockGetAllAccounts/mockGetAllAccounts');
  const {
    mockAquireTokenSilent,
  } = require('./mocks/functions/mockAquireTokenSilent/mockAquireTokenSilent');
  const { mockSsoSilent } = require('./mocks/functions/mockSsoSilent/mockSsoSilent');

  msalInstance.getAllAccounts = mockGetAllAccounts;
  msalInstance.acquireTokenSilent = mockAquireTokenSilent;
  msalInstance.ssoSilent = mockSsoSilent;
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Router>
    <MsalProvider instance={msalInstance}>
      <App pca={msalInstance} />
    </MsalProvider>
  </Router>
);
