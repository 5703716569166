import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import { ssoLoginSilent } from './authConfig';
import Home from './containers/Home';
import NotFound from './containers/NotFound/NotFound';
import RoomDetails from './containers/RoomDetails';
import { BookingProvider } from './providers/bookingProvider';
import { DateProvider } from './providers/dateProvider';
import { RoomAvailabilityProvider } from './providers/roomAvailabilityProvider';
import rooms from './rooms.json';
import { CustomNavigationClient } from './utils';

import Header from './components/Header/Header';
import Login from './components/Login/Login';

import s from './App.module.scss';

const App = ({ pca }) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  const queryClient = new QueryClient();

  useEffect(() => {
    ssoLoginSilent(pca);
  }, [pca]);

  return (
    <>
      <AuthenticatedTemplate>
        <QueryClientProvider client={queryClient}>
          <BookingProvider>
            <DateProvider>
              <RoomAvailabilityProvider>
                <Header />
                <div className={s.pageWrapper}>
                  <Routes>
                    <Route path="/" exact element={<Home rooms={rooms} />} />
                    <Route path="/rooms/:roomId" element={<RoomDetails rooms={rooms} />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                  <ReactQueryDevtools initialIsOpen={false} />
                </div>
              </RoomAvailabilityProvider>
            </DateProvider>
          </BookingProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
};

export default App;
