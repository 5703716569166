export const spaceOrEnterToClick: React.KeyboardEventHandler<unknown> = (e): void => {
  if (['Enter', ' ', 'Space'].includes(e.key)) {
    e.preventDefault();
    e.stopPropagation();
    const t = e.target as any;
    if (t.click) {
      t.click();
    }
  }
};
