import { ReactNode, createContext, useContext, useState } from 'react';

import rooms from '../rooms.json';

interface IRoomAvailabilityProvider {
  children?: ReactNode;
}

interface IRoomAvailabilityContext {
  setRoomAvailability: (id: string, isAvailable: boolean) => void;
  isRoomAvailable: (id: string) => boolean;
  activeRoomId: string | null;
  setActiveRoomId: React.Dispatch<React.SetStateAction<null | string>>;
}

const roomsAvailabilityInit: Record<string, boolean> = rooms
  .map(({ id }) => id)
  .reduce((a, v) => ({ ...a, [v]: false }), {});

const RoomAvailabilityContext = createContext<IRoomAvailabilityContext | undefined>(undefined);

const RoomAvailabilityProvider = ({ children }: IRoomAvailabilityProvider) => {
  const [roomsAvailability, setRoomsAvailability] = useState(roomsAvailabilityInit);
  const [activeRoomId, setActiveRoomId] = useState<null | string>(null);

  const setRoomAvailability = (id: string, isAvailable: boolean) => {
    setRoomsAvailability((prev) => ({ ...prev, [id]: isAvailable }));
  };

  const isRoomAvailable = (id: string) => roomsAvailability[id];

  return (
    <RoomAvailabilityContext.Provider
      value={{ setRoomAvailability, isRoomAvailable, activeRoomId, setActiveRoomId }}
    >
      {children}
    </RoomAvailabilityContext.Provider>
  );
};

const useRoomAvailability = () => {
  const context = useContext(RoomAvailabilityContext);

  if (!context) {
    throw new Error('Wrap consumer of useRoomAvailability in RoomAvailabilityProvider');
  }

  return context;
};

export { RoomAvailabilityProvider, useRoomAvailability };
