import Icon from '../Icon/Icon';
import classNames from 'classnames';

import s from './Button.module.scss';

type TColor = 'green' | 'yellow' | 'red' | 'primary' | 'transparent';
type TVariant = 'iconButton' | 'solid';
type TSize = 'small' | 'medium' | 'large';

interface IButton {
  children: React.ReactNode;
  variant?: TVariant;
  color?: TColor;
  size?: TSize;
  disabled?: boolean;
  loading?: boolean;
  success?: boolean;
  onClick: () => void;
  dataCy?: string;
  fullWidth?: boolean;
  noShadow?: boolean;
  active?: boolean;
  tabIndex?: number;
  className?: string;
}

export const Button = ({
  children,
  color = 'green',
  variant = 'solid',
  size = 'medium',
  disabled = false,
  loading = false,
  success = false,
  onClick,
  dataCy,
  fullWidth = false,
  noShadow = false,
  active = false,
  tabIndex,
  className,
}: IButton) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled || loading}
      className={classNames(
        s.button,
        s[variant],
        s[color],
        s[size],
        loading && s.spinner,
        noShadow && s.noShadow,
        fullWidth && s['full-width'],
        active && s.active,
        (disabled || loading) && [s.disabled],
        className
      )}
      data-cy={dataCy}
      tabIndex={tabIndex}
    >
      {!loading && !success && children}
      {success && <Icon name="checkmarkOutline" className={classNames(s.pulse)} color="black" />}
    </button>
  );
};

export default Button;
