import React, { ReactNode, useRef } from 'react';
import ReactDOM from 'react-dom';

import s from './Tooltip.module.scss';

interface ITooltip {
  isVisible: boolean;
  targetRef: React.RefObject<HTMLDivElement>;
  children: ReactNode;
}

const Tooltip = ({ children, isVisible, targetRef }: ITooltip) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const renderContent = () => {
    if (targetRef?.current) {
      const pos = targetRef.current.getBoundingClientRect();
      // Hack to get the container in center
      const targetWidth = targetRef.current.clientWidth;

      // need to account for the scroll offset, if we have scrolled vertically.
      const scrollOffset = window.pageYOffset;

      return (
        <div
          className={s.tooltipWrapper}
          style={{
            top: pos?.y - 40 + scrollOffset, // 2em + padding + scrollOffset
            left: pos?.x,
            width: targetWidth,
          }}
        >
          <div ref={tooltipRef} className={s.tooltipContainer}>
            {children}
          </div>
        </div>
      );
    }
  };

  if (!isVisible) return <></>;

  return ReactDOM.createPortal(renderContent(), document.body);
};

export default Tooltip;
