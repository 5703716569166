import React from 'react';

import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const CheckmarkSolid = ({ color, width, height }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.9694 15.5105 10.7788 15.5893 10.58 15.5893C10.3812 15.5893 10.1906 15.5105 10.05 15.37L7.22 12.54C7.08052 12.3989 7.0023 12.2084 7.0023 12.01C7.0023 11.8116 7.08052 11.6211 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7V9.7Z"
        fill={color}
      />
    </Svg>
  );
};

export default CheckmarkSolid;
