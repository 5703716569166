import { useState } from 'react';

import { MeetingAttendee } from '../../types/organization-user';
import { parseTimeToString } from '../../utils';
import Icon from '../Icon/Icon';
import Pill from '../Pill/Pill';

import s from './EventList.module.scss';

interface IEvent {
  attendees: MeetingAttendee[];
  subject: string;
  organizer: string;
  startTime: Date;
  endTime: Date;
  description: string;
  isMobile: boolean;
  isOverlap?: boolean;
}

const Event = ({
  attendees,
  startTime,
  endTime,
  subject,
  organizer,
  description,
  isOverlap,
  isMobile,
}: IEvent) => {
  const [expanded, setExpanded] = useState(false);

  if (isMobile) {
    return (
      <div
        onClick={() => setExpanded((prev) => !prev)}
        className={`${s['event-schedule']} scheduled-event`}
      >
        <div className={s['description-container']}>
          <div className={`${s['description-grid']} ${expanded ? s['expanded'] : ''} `}>
            <h3 className={`${isOverlap ? s['error-text'] : ''}`}>
              {`${parseTimeToString(startTime)}-${parseTimeToString(endTime)}`}
            </h3>

            {!expanded && (
              <p className={s['no-overflow']}>
                {isOverlap ? 'Timeslot not available' : subject || organizer}
              </p>
            )}
            <div className={s['expand-icon']}>
              <Icon name={expanded ? 'chevronUp' : 'chevronDown'} />
            </div>
          </div>

          {expanded && (
            <>
              <h4>{isOverlap ? 'Timeslot not available' : subject || organizer}</h4>
              <div className={s['pill-container']}>
                <Pill color="highlight">{organizer}</Pill>
                {attendees.map((attendee) => (
                  <Pill minWidth key={attendee.displayName} color={'default'}>
                    {attendee.displayName}
                  </Pill>
                ))}

                {description !== '' && (
                  <div className={s['body-container']}>
                    <p> {description}</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`${s['event-schedule']} scheduled-event`}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <div className={s['description-container']}>
          <div>
            <div className={s['inner']}>
              <h3>{subject || organizer}</h3>
            </div>

            {isOverlap ? (
              <p className={s['error-text']}>Can't book meeting at this timeslot</p>
            ) : (
              <p>{`${parseTimeToString(startTime)}-${parseTimeToString(endTime)}`}</p>
            )}
          </div>
          <div className={s['pill-container']}>
            <Pill color="highlight">{organizer}</Pill>
            {attendees.map((attendee) => (
              <Pill minWidth key={attendee.displayName} color={'default'}>
                {attendee.displayName}
              </Pill>
            ))}
            {description !== '' && (
              <div className={s['body-container']}>
                <p> {description}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Event;
