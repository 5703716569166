import { useState } from 'react';

import { useMsal } from '@azure/msal-react';

import { DURATIONS } from '../../config';
import useAllScheduleItems from '../../hooks/useAllScheduleItems';
import { useBooking } from '../../providers/bookingProvider';
import { useRoomAvailability } from '../../providers/roomAvailabilityProvider';
import rooms from '../../rooms.json';
import { getQuickBookRoom, getTimeslotLabel, minutesTo } from '../../utils';
import BookButton from '../BookingForm/BookButton';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import InputRange from '../Input/InputRange';
import StatusMessage from '../StatusMessage/StatusMessage';
import classNames from 'classnames';

import s from './QuickBook.module.scss';

const QuickBook = () => {
  const { accounts } = useMsal();
  const { duration, setDuration } = useBooking();
  const [showSuggestion, setShowSuggestion] = useState(false);
  const roomScheduleRecord = useAllScheduleItems();
  const [nrAttendees, setNrAttendees] = useState(1);

  const { setActiveRoomId } = useRoomAvailability();

  const durationIndex = DURATIONS.findIndex(({ value }) => value === duration);

  const roomSuggestion = roomScheduleRecord
    ? getQuickBookRoom(roomScheduleRecord, nrAttendees, duration)
    : null;

  const { info } = rooms.find((room) => room.id === roomSuggestion?.room.id) || {};

  return (
    <div className={s.container}>
      <div className={s.header}>
        <h4 className={s.heading}>Quick Book</h4>
        {showSuggestion && roomSuggestion ? (
          <div>
            <StatusMessage variant="green">{roomSuggestion?.room.name}</StatusMessage>
            <span className={s.timeslot}>
              <Icon color="white" outlineColor="black" name="clockSolidDuo" />
              {/* INFO: Disgusting workaround to get the correct time labels. The function to parse
              time to string is coupled with LOCAL timezone calculations as opposed to the timezone of the date it is passed */}
              {getTimeslotLabel(
                new Date(
                  roomSuggestion.timeslotStart.getTime() +
                    new Date().getTimezoneOffset() * minutesTo.milliseconds
                ),
                duration
              )}
            </span>
          </div>
        ) : (
          <p>Suggest next available booking to your needs</p>
        )}
      </div>
      {showSuggestion ? (
        <>
          <div className={s.roomInformationContainer}>
            <p>{info}</p>
          </div>
          <div className={s.buttonsContainer}>
            <BookButton
              isDisabled={!roomSuggestion?.room}
              userDisplayName={accounts[0].username}
              roomEmail={roomSuggestion?.room.email}
              roomId={roomSuggestion?.room.id}
              roomName={roomSuggestion?.room.name}
              dataCy="quickBookButton"
              timeslot={roomSuggestion?.timeslotStart}
              duration={DURATIONS[durationIndex].value}
              size={undefined}
              isRefetching={undefined}
            >
              <span>Book</span> <Icon name="people" color="black" size="sm" />
            </BookButton>
            <Button
              color="yellow"
              onClick={() => {
                setShowSuggestion(false);
                setActiveRoomId(null);
              }}
            >
              Edit
            </Button>
          </div>
        </>
      ) : (
        <div className={classNames(s.buttonsContainer, s.buttonsContainerColumn)}>
          <div className={s.inputContainer}>
            <div className={s.attendees}>
              <InputRange
                value={`${nrAttendees}`} // TODO: add icon
                onIncrement={() => setNrAttendees((prev) => prev + 1)}
                onDecrement={() => setNrAttendees((prev) => prev - 1)}
                isIncrementDisabled={nrAttendees >= 10} // TODO: find largest room
                isDecrementDisabled={nrAttendees === 1}
                iconType="operators"
                size="small"
                iconSuffix={<Icon name="person" size="sm" color="black" />}
              />
            </div>
            <div className={s.duration}>
              <InputRange
                value={DURATIONS[durationIndex].label.toUpperCase()}
                iconType="operators"
                onIncrement={() => setDuration(DURATIONS[durationIndex + 1].value)}
                onDecrement={() => setDuration(DURATIONS[durationIndex - 1].value)}
                isIncrementDisabled={durationIndex === DURATIONS.length - 1}
                isDecrementDisabled={durationIndex === 0}
                size="small"
              />
            </div>
          </div>
          <Button
            onClick={() => {
              setShowSuggestion(true);
              setActiveRoomId(roomSuggestion?.room.id);
            }}
          >
            Suggest Room
          </Button>
        </div>
      )}
    </div>
  );
};

export default QuickBook;
