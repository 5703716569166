import rooms from '../../rooms.json';
import OngoingMeeting from '../OngoingMeeting/OngoingMeeting';
import Room from '../Room/Room';
import { MeetingInfo } from '@microsoft/microsoft-graph-types';

import s from './Sidebar.module.scss';

interface ISidebar {
  ongoingEvent: MeetingInfo;
  activeRoomId: string;
}

const Sidebar = ({ activeRoomId, ongoingEvent }: ISidebar) => {
  return (
    <div className={s.cardGrid} data-cy="rooms">
      {Boolean(ongoingEvent) && <OngoingMeeting event={ongoingEvent} />}
      {rooms
        .sort((a, b) => a.size - b.size)
        .map((room) => (
          <Room key={room.id} room={room} isActive={room.id === activeRoomId} />
        ))}
    </div>
  );
};

export default Sidebar;
