import { IIconProps } from '../../../types/icon-props';
import Svg from './Svg';

const SignOut = ({ color, width, height }: IIconProps) => {
  return (
    <Svg width={width} height={height}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 20a1 1 0 0 0-1-1H5V5h5a1 1 0 1 0 0-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h5a1 1 0 0 0 1-1z"
        fill={color}
      />
      <path
        d="M21.714 12.7a.996.996 0 0 0 .286-.697v-.006a.997.997 0 0 0-.293-.704l-4-4a1 1 0 1 0-1.414 1.414L18.586 11H9a1 1 0 1 0 0 2h9.586l-2.293 2.293a1 1 0 0 0 1.414 1.414l4-4 .007-.007z"
        fill={color}
      />
    </Svg>
  );
};

export default SignOut;
